import React, { useEffect, useState } from 'react';
import { paymentProviderLogo } from '@/utils/utils';

interface Logo {
  src: string;
  alt: string;
}

const BANKING_LOGOS_SV: Logo[] = [
  {
    src: paymentProviderLogo('swedbank.png'),
    alt: 'Swedbank',
  },
  {
    src: paymentProviderLogo('nordea.png'),
    alt: 'Nordea',
  },
  {
    src: paymentProviderLogo('handelsbanken.png'),
    alt: 'Handelsbanken',
  },
  {
    src: paymentProviderLogo('seb.png'),
    alt: 'SEB',
  },
  {
    src: paymentProviderLogo('lansforsakringar.png'),
    alt: 'Länsförsäkringar',
  },
  {
    src: paymentProviderLogo('icabanken.png'),
    alt: 'ICA Banken',
  },
  {
    src: paymentProviderLogo('danske-bank.png'),
    alt: 'Danske Bank',
  },
  {
    src: paymentProviderLogo('skandia.png'),
    alt: 'Skandia',
  },
];

const BANKING_LOGOS_FI: Logo[] = [
  {
    src: paymentProviderLogo('op-bank.png'),
    alt: 'OP Bank',
  },
  {
    src: paymentProviderLogo('nordea.png'),
    alt: 'Nordea',
  },
  {
    src: paymentProviderLogo('s-pankki.png'),
    alt: 'S Pankki',
  },
  {
    src: paymentProviderLogo('danske-bank.png'),
    alt: 'Danske Bank',
  },
  {
    src: paymentProviderLogo('saastopankki.png'),
    alt: 'Säästöpankki',
  },
  {
    src: paymentProviderLogo('pop-pankki.png'),
    alt: 'POP Pankki',
  },
  {
    src: paymentProviderLogo('omasp.png'),
    alt: 'Oma Säästöpankki',
  },
  {
    src: paymentProviderLogo('aktia.png'),
    alt: 'Aktia',
  },
];

export function HeroBankingLogos({ locale }: { locale: string }): JSX.Element | null {
  const [logos, setLogos] = useState<Logo[]>([]);

  useEffect(() => {
    if (locale === 'sv') {
      setLogos(BANKING_LOGOS_SV);
      return;
    }

    if (locale === 'fi') {
      setLogos(BANKING_LOGOS_FI);
      return;
    }
  }, [locale]);

  if (!logos || logos.length === 0) {
    return null;
  }

  return (
    <div className="bottom-0 left-0 hidden w-full flex-col px-8 text-white opacity-70 md:absolute md:flex md:py-4">
      <div className="mx-auto grid w-full max-w-[1400px] grid-cols-4 gap-x-8 md:grid-cols-8">
        {logos.map(logo => (
          <div className="mx-auto w-full max-w-[64px]" key={logo.src}>
            <img
              className="pointer-events-none"
              width="152"
              height="152"
              src={logo.src}
              alt={logo.alt}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
