import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

export enum HomeHeroAuthenticationStep {
  Login,
  Registration,
  ForgotPassword,
  ForgotPasswordSuccess,
  TwoFactorAuthentication,
}

export interface AuthenticationStore {
  homeHeroAuthenticationStep: HomeHeroAuthenticationStep;
  username?: string;
  password?: string;
}

type AuthenticationActions = {
  setHomeHeroAuthenticationStep: (homeHeroAuthenticationStep: HomeHeroAuthenticationStep) => void;
  setAuthenticationCredentials: (username: string, password: string) => void;
  reset: () => void;
};

type State = AuthenticationStore;

const initialState: State = {
  homeHeroAuthenticationStep: HomeHeroAuthenticationStep.ForgotPasswordSuccess,
  username: undefined,
  password: undefined,
};

export const useHomeHeroAuthenticationStep = create<{ data: State } & AuthenticationActions>(
  set => ({
    data: initialState,
    setHomeHeroAuthenticationStep: (homeHeroAuthenticationStep: HomeHeroAuthenticationStep) =>
      set(state => ({ data: { ...state.data, homeHeroAuthenticationStep } })),
    setAuthenticationCredentials: (username, password) =>
      set(state => ({
        data: {
          username,
          password,
          homeHeroAuthenticationStep: state.data.homeHeroAuthenticationStep,
        },
      })),
    reset: () => set({ data: initialState }),
  }),
);

export const useHomeHeroAuthenticationStepData = () => {
  return useHomeHeroAuthenticationStep(state => state.data, shallow);
};

export const useHomeHeroAuthenticationStepActions = () => {
  return useHomeHeroAuthenticationStep(
    state => ({
      setHomeHeroAuthenticationStep: state.setHomeHeroAuthenticationStep,
      setAuthenticationCredentials: state.setAuthenticationCredentials,
      reset: state.reset,
    }),
    shallow,
  );
};
