import React, { Suspense } from 'react';
import HomeHeroContainer from './HomeHeroContainer';
import { useSelector } from 'react-redux';
import HomeHeroContentPublic from './HomeHeroContentPublic';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectLocale } from '@/store/selectors/commonSelectors';
import LoadingSpinner from '@/components/shared/spinner/LoadingSpinner';

const HomeHeroContentPrivate = React.lazy(() => import('./HomeHeroContentPrivate'));
const HomeHero = (): JSX.Element => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const locale = useSelector(selectLocale);
  return (
    <HomeHeroContainer isAuthenticated={isAuthenticated}>
      {!isAuthenticated && <HomeHeroContentPublic locale={locale} />}
      {isAuthenticated && (
        <Suspense fallback={<LoadingSpinner />}>
          <HomeHeroContentPrivate />
        </Suspense>
      )}
    </HomeHeroContainer>
  );
};

export default HomeHero;
