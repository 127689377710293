import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { useLogin } from '@/hooks/useLogin';
import { BffApiErrorCode } from '@/models/bff-api-error';
import { BffErrorCode } from '@lucky7ventures/bff-types';
import { BtnBlueLink } from '@/components/buttons/BtnBlueLink';
import { OpenedFrom } from '@/lib/gTagManager';

const ErrorMessage = ({ children }: { children: React.ReactNode }) => (
  <div className="text-red-500 text-[14px] leading-[15px] text-left font-bold">{children}</div>
);

const MexicoRegistrationErrors = ({ errorCode }: { errorCode: BffApiErrorCode }) => {
  const intl = useIntl();
  const { openLogin } = useLogin();

  if (errorCode === BffErrorCode.GIG_IDENTIFICATION_NUMBER_ALREADY_IN_USE) {
    return (
      <ErrorMessage>
        <FormattedMessage
          id="error.curp.duplicate"
          values={{
            button: (str: string) => (
              <BtnBlueLink
                text={str}
                onClickHandler={() => openLogin({ openedFrom: OpenedFrom.MexicoRegistration })}
              />
            ),
          }}
        />
      </ErrorMessage>
    );
  }

  if (
    [BffErrorCode.VALIDATE_CURP_INVALID_FORMAT, BffErrorCode.VALIDATE_CURP_NO_CURP_DATA].includes(
      errorCode as BffErrorCode,
    )
  ) {
    return (
      <ErrorMessage>
        <FormattedMessage
          id="error.curp.invalidFormat"
          values={{
            a: str => (
              <a
                href="https://www.gob.mx/curp/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-blue font-bold underline cursor-pointer"
              >
                {str}
              </a>
            ),
          }}
        />
      </ErrorMessage>
    );
  }

  if (
    [BffErrorCode.GIG_USERNAME_ALREADY_EXISTS, BffErrorCode.GIG_EMAIL_ALREADY_EXISTS].includes(
      errorCode as BffErrorCode,
    )
  ) {
    return (
      <ErrorMessage>
        <FormattedMessage
          id="error.emailAccountExists"
          values={{
            b: str => (
              <span
                onClick={() => openLogin({ openedFrom: OpenedFrom.MexicoRegistration })}
                className="text-blue-blue font-bold underline cursor-pointer"
              >
                {str}
              </span>
            ),
          }}
        />
      </ErrorMessage>
    );
  }

  return (
    <ErrorMessage>
      {intl.formatMessage({ id: 'failedSignup.SomethingWentWrongMessage' })} ({errorCode})
    </ErrorMessage>
  );
};

export default MexicoRegistrationErrors;
