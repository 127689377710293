import { LOW_BALANCE_THRESHOLDS } from '@/constants/constants';

export const hasLowBalance = (depositCount, currentBalance, currency) => {
  if (depositCount < 1) {
    return false;
  }

  const lowBalanceThreshold = LOW_BALANCE_THRESHOLDS[currency];

  return lowBalanceThreshold > currentBalance;
};
