import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { isSplashTechEnabled } from '@/utils/logic.utils';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { SPLASHTECH_CLIENT, SPLASHTECH_URL } from '@/constants/constants';
import { selectUserIsRewardReelTester } from '@/store/selectors/userSelectors';

export function useRewardsWheel(): { isEnabled: boolean; url: string | null } {
  const locale = useSelector(selectLocale);
  const token = useSelector(selectAuthToken);
  const isRewardsReelTester = useSelector(selectUserIsRewardReelTester);

  return {
    isEnabled: isSplashTechEnabled(locale) && isRewardsReelTester,
    url:
      token && isRewardsReelTester
        ? `${SPLASHTECH_URL}?client=${SPLASHTECH_CLIENT}&game=WHEEL&token=${token}`
        : null,
  };
}
