import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { PayNPlayHome } from '@/features/authentication/pay-n-play/PayNPlayHome';
import { HeroBankingLogos } from '@/components/HeroBankingLogos';
import { cdnImage } from '@/utils/image-utils';

const HomeHeroContentPublicPayNPlay = (): JSX.Element => {
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  return (
    <div className="flex w-full flex-col">
      <div className="mx-auto flex w-full max-w-6xl py-16 px-6 md:space-x-8 pb-24">
        <div className="relative mb-10 hidden w-full flex-col justify-center text-white md:flex">
          <h1 className="mb-0 text-5xl font-bold leading-[3.2rem] text-white">
            {intl.formatMessage({ id: 'hero.welcome' })}
            <span className="block">{intl.formatMessage({ id: 'hero.luckyDays' })}</span>
          </h1>
          <div className="mt-6 space-y-4 text-xl">
            <p>{intl.formatMessage({ id: 'hero.tagline' })}</p>
            <p>
              <FormattedMessage
                id="hero.tagline2"
                values={{
                  b: str => <span className="font-bold">{str}</span>,
                }}
              />
            </p>
            <div className="flex items-center gap-4 flex-wrap">
              <img
                src={cdnImage('trustly.png', { width: '130px', height: 'auto', dpr: '2' })}
                className="brightness-0 invert"
                alt="Trustly logo"
                width="130px"
              />
              <img
                src={cdnImage('bankid.png', { width: '32px', height: 'auto', dpr: '2' })}
                className="brightness-0 invert"
                alt="BankID logo"
                width="32px"
              />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-center justify-center text-center md:justify-end">
          <PayNPlayHome />
        </div>
      </div>
      <HeroBankingLogos locale={locale} />
    </div>
  );
};

export default HomeHeroContentPublicPayNPlay;
