import React, { useRef } from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { DateTime } from 'luxon';

import { Button } from '@/components/buttons/Button';
import Tooltip from '@/components/Tooltip';
import {
  BasicRegistrationInfo,
  useBasicInfoData,
  useRegistrationActions,
} from '@/store/registrationStore';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import Form from '@/components/form/Form';
import { openModal } from '@/store/actions/modal';
import EmailField from '@/components/form/email/EmailField';
import PasswordField from '@/components/form/password/PasswordField';
import DateOfBirthField from '@/components/form/date-of-birth/DateOfBirthField';
import PhoneNumberField from '@/components/form/phone-number/PhoneNumberField';
import SwitchField from '@/components/form/switch/SwitchField';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import { BffErrorCode, BffValidateEmailPhoneResponse } from '@lucky7ventures/bff-types';
import BffApiService from '@/shared/bffApiService';

const BasicInfoForm = ({ stepForward }: { stepForward: () => void }) => {
  const { request, loading, data } = useBffApiRequest<BffValidateEmailPhoneResponse>();
  const { email, password, birthDate, mobile, fitToPlayConsent } = useBasicInfoData();
  const { updateField, updateFields } = useRegistrationActions();
  const prevEmailRef = useRef('');
  const prevMobileRef = useRef({
    number: '',
    country: '',
    prefix: '',
    wholeNumber: '',
  });
  const intl = useIntl();
  const dispatch = useDispatch();

  const initialValues: BasicRegistrationInfo = {
    email,
    password,
    birthDate: {
      day: birthDate.day,
      month: birthDate.month,
      year: birthDate.year,
    },
    mobile: {
      number: mobile.number,
      country: mobile.country,
      prefix: mobile.prefix,
      wholeNumber: mobile.wholeNumber,
    },
    fitToPlayConsent,
  };

  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 100;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .email(intl.formatMessage({ id: 'inputs.email.invalid' })),
    password: Yup.string()
      .trim()
      .test(
        'no-whitespace',
        intl.formatMessage({ id: 'inputs.password.whitespaces.invalid' }),
        value => !(value && /\s/.test(value)),
      )
      .matches(/^\S{6,}$/, intl.formatMessage({ id: 'inputs.password.invalid' }))
      .required(intl.formatMessage({ id: 'input.error.required' })),
    birthDate: Yup.object()
      .shape({
        day: Yup.string()
          .required(intl.formatMessage({ id: 'input.error.required' }))
          .test(
            'valid-day',
            intl.formatMessage({ id: 'inputs.date.day.invalid' }),
            function (value) {
              const month = this.parent.month;
              const year = this.parent.year;
              if (!value || !month || !year) {
                return true; // Skip validation if any field is missing
              }
              const numericDay = parseInt(value);
              const daysInMonth = new Date(parseInt(year), parseInt(month), 0).getDate();
              return numericDay <= daysInMonth && numericDay > 0;
            },
          ),
        month: Yup.string()
          .required(intl.formatMessage({ id: 'input.error.required' }))
          .matches(/^(0?[1-9]|1[0-2])$/, intl.formatMessage({ id: 'inputs.date.month.invalid' })),
        year: Yup.string()
          .required(intl.formatMessage({ id: 'input.error.required' }))
          .matches(/^(19|20)\d\d$/, intl.formatMessage({ id: 'inputs.date.year.invalid' }))
          .test('is-valid-year', 'Invalid year range', value => {
            const numericYear = parseInt(value ?? '', 10);
            return numericYear >= minYear && numericYear <= currentYear;
          }),
      })
      .test(
        'is-19-plus',
        intl.formatMessage({ id: 'inputs.birthdate.invalid' }),
        function ({ day, month, year }) {
          if (!day || !month || !year) {
            return false;
          }
          const birthDate = DateTime.local(parseInt(year), parseInt(month), parseInt(day));
          const age = DateTime.now().diff(birthDate, 'years').years;
          return age >= 19;
        },
      ),
    mobile: Yup.object().shape({
      wholeNumber: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
      number: Yup.string().min(5, intl.formatMessage({ id: 'inputs.mobile.invalid' })),
    }),
    fitToPlayConsent: Yup.boolean().test(
      'fit-to-play',
      intl.formatMessage({ id: 'inputs.fitToPlay.invalid' }),
      value => !!value,
    ),
  });

  const handleOpenLogin = () => {
    dispatch(openModal('login'));
  };

  const setEmailPhoneFieldErrors = (
    emailError: BffErrorCode | undefined,
    phoneError: BffErrorCode | undefined,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    if (emailError) {
      switch (emailError) {
        case BffErrorCode.GIG_EMAIL_ALREADY_EXISTS:
          setFieldError(
            'email',
            intl.formatMessage({ id: 'registration.ontario.email.alreadyExists' }),
          );
          triggerGTag(GTagEvents.ont_reg_email_exists_error);
          break;
        default:
          setFieldError('email', intl.formatMessage({ id: 'inputs.email.invalid' }));
      }
    }

    if (phoneError) {
      switch (phoneError) {
        case BffErrorCode.GIG_MOBILE_AND_PREFIX_COMBINATION_ALREADY_REGISTERED:
          triggerGTag(GTagEvents.ont_reg_mobile_exists_error);
          setFieldError(
            'mobile.wholeNumber',
            intl.formatMessage({ id: 'registration.ontario.mobile.alreadyExists' }),
          );
          break;
        case BffErrorCode.GIG_MOBILE_OR_PREFIX_INVALID:
          triggerGTag(GTagEvents.ont_reg_mobile_invalid_error);
          setFieldError('mobile.wholeNumber', intl.formatMessage({ id: 'inputs.mobile.invalid' }));
          break;
        default:
          setFieldError('mobile.wholeNumber', intl.formatMessage({ id: 'inputs.mobile.invalid' }));
      }
    }
  };

  const handleOnSubmit = (
    values: BasicRegistrationInfo,
    { setFieldError }: FormikHelpers<BasicRegistrationInfo>,
  ) => {
    triggerGTag(GTagEvents.ont_reg_step1_continue_cta_click);
    updateFields(values);
    const successCallback = ({ emailError, phoneError }: BffValidateEmailPhoneResponse) => {
      if (!emailError && !phoneError) {
        stepForward();
      }

      setEmailPhoneFieldErrors(emailError, phoneError, setFieldError);
    };

    // We want to trigger the validation request only if there
    // was a change in the email or mobile field values between submits
    if (
      prevEmailRef.current !== values.email ||
      prevMobileRef.current.wholeNumber !== values.mobile.wholeNumber
    ) {
      request({
        apiMethod: BffApiService.validateEmailPhone,
        payload: {
          email: values.email,
          prefix: values.mobile.prefix,
          phone: values.mobile.number,
        },
        successCallback,
      });
    } else {
      if (!data?.emailError && !data?.phoneError) {
        stepForward();
      } else {
        // We need to manually set up the errors again, since Formik clears
        // errors state on submit
        setEmailPhoneFieldErrors(data.emailError, data.phoneError, setFieldError);
      }
    }
    prevEmailRef.current = values.email;
    prevMobileRef.current = values.mobile;
  };

  return (
    <div className="w-full h-full flex flex-col gap-7">
      <span className="text-white text-sm text-center">
        <FormattedMessage
          id="registration.ontario.durationUsp"
          values={{
            b: str => <span className="font-bold text-blue-blue">{str}</span>,
          }}
        />
      </span>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        noValidate
        className="flex flex-col justify-between gap-8"
      >
        {() => (
          <>
            <div className="flex flex-col gap-7">
              <EmailField
                data-cy="basic_info_email"
                label={intl.formatMessage({ id: 'inputs.email' })}
                name="email"
                onBlur={({ target: { value } }) => updateField('email', value)}
                onValidationError={error => triggerGTag(GTagEvents.ont_reg_email_error, { error })}
                onFocus={() => triggerGTag(GTagEvents.ont_reg_email_click)}
                autoComplete="off"
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
              />
              <PasswordField
                data-cy="basic_info_password"
                label={intl.formatMessage({ id: 'inputs.password' })}
                name="password"
                onBlur={({ target: { value } }) => updateField('password', value)}
                onValidationError={error =>
                  triggerGTag(GTagEvents.ont_reg_password_error, { error })
                }
                onFocus={() => triggerGTag(GTagEvents.ont_reg_password_click)}
                autoComplete="new-password"
                placeholder={intl.formatMessage({
                  id: 'registration.ontario.password.placeholder',
                })}
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
              />
              <DateOfBirthField
                label={intl.formatMessage({ id: 'inputs.birthdate' })}
                name="birthDate"
                onFocus={() => triggerGTag(GTagEvents.ont_reg_dob_click)}
                onBlur={date => updateField('birthDate', date)}
                onValidationError={error => triggerGTag(GTagEvents.ont_reg_dob_error, { error })}
                endIcon={
                  <Tooltip
                    position="left"
                    text={intl.formatMessage({
                      id: 'registration.ontario.dob.tooltip',
                    })}
                    className="max-w-[220px] sm:max-w-max"
                  >
                    <InformationCircleIcon className="w-5 h-5 text-white" />
                  </Tooltip>
                }
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
              />
              <PhoneNumberField
                label={intl.formatMessage({ id: 'inputs.mobile' })}
                name="mobile"
                onBlur={({ target: { value } }) => {
                  const [prefix, ...rest] = value.split(' ');
                  updateField('mobile', {
                    prefix,
                    number: rest.join('').replace(/[^0-9]/g, ''),
                    wholeNumber: value,
                    country: '', // react phone input lib will get country from the prefix
                  });
                }}
                onFocus={() => triggerGTag(GTagEvents.ont_reg_mobile_click)}
                onValidationError={error => triggerGTag(GTagEvents.ont_reg_mobile_error, { error })}
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue
                [&_input]:placeholder:!text-white/60"
              />
              <SwitchField
                name="fitToPlayConsent"
                label={intl.formatMessage({ id: 'inputs.fitToPlay' })}
                labelClassName="text-white"
                onChange={(checked: boolean) => {
                  updateField('fitToPlayConsent', checked);
                  triggerGTag(GTagEvents.ont_reg_consent_fit_to_play_click);
                }}
                onValidationError={error =>
                  triggerGTag(GTagEvents.ont_reg_consent_fit_to_play_error, { error })
                }
              />
            </div>
            <div className="flex flex-col items-center gap-4 mt-4">
              <Button
                text={intl.formatMessage({ id: 'misc.continue' })}
                type="submit"
                btnType="primary"
                isLoading={loading}
              />
              <div className="text-white text-[15px] text-center">
                <FormattedMessage
                  id="fastReg.login"
                  values={{
                    a: str => (
                      <span
                        onClick={handleOpenLogin}
                        className="text-blue-blue font-bold underline cursor-pointer"
                      >
                        {str}
                      </span>
                    ),
                  }}
                />
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default BasicInfoForm;
