import { useEffect } from 'react';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
} from '@/store/authentication/AuthenticationStore';
import StorageManager from '@/shared/storageManager';

const useReturningUser = () => {
  const isReturningUser = StorageManager.getIsReturningUser();
  const { setHomeHeroAuthenticationStep } = useHomeHeroAuthenticationStepActions();

  useEffect(() => {
    if (isReturningUser) {
      setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Login);
    } else {
      setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Registration);
    }
  }, [isReturningUser]);
};

export default useReturningUser;
