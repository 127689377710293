import React, { Suspense, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectAutoLoginFinished } from '@/store/selectors/autoLoginSelectors';
import { useCashierShow } from '@/features/cashier/useCashier';
import { openModal } from '@/store/actions/modal';
import HomeHero from '@/pages/home/hero/HomeHero';
import { envIsMexico } from '@/constants/constants';

const HomeNormal = React.lazy(() => import('@/pages/home/HomeNormal'));
const HomeMexico = React.lazy(() => import('@/pages/home/HomeMexico'));

function Home({ cashierDrawerOpen }: { cashierDrawerOpen?: boolean }) {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const autoLoginFinished = useSelector(selectAutoLoginFinished);
  const dispatch = useDispatch();
  const showCashier = useCashierShow();

  useEffect(() => {
    // If the user is logged in and "redirected"
    // from cashier page, open the cashier drawer
    if (cashierDrawerOpen && autoLoginFinished && isAuthenticated) {
      showCashier();
    }

    // Open the Login modal when logged-out users
    // are "redirected" to home page from cashier page
    if (cashierDrawerOpen && autoLoginFinished && !isAuthenticated) {
      dispatch(openModal('login'));
    }
  }, [isAuthenticated, autoLoginFinished, showCashier]);

  return (
    <>
      <HomeHero />
      <Suspense fallback={<div />}>
        {!envIsMexico() && <HomeNormal />}
        {envIsMexico() && <HomeMexico />}
      </Suspense>
    </>
  );
}

export default Home;
