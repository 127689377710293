import React, { useEffect } from 'react';
import 'react-phone-number-input/style.css';

import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import CompleteFastRegProfile from '@/components/shared/complete-fast-reg/CompleteFastRegProfile';
import ModalLayout from '@/components/Modals/ModalLayout';

const UserProfileCompletionModal = ({ closeModal }: { closeModal: () => void }) => {
  useEffect(() => {
    triggerGTag(GTagEvents.complete_profile_modal_open);
  }, []);

  const handleCloseModal = () => {
    triggerGTag(GTagEvents.complete_profile_modal_close);
    closeModal();
  };

  const onSuccessHandler = () => {
    setTimeout(() => {
      handleCloseModal();
    }, 2000);
  };

  return (
    <ModalLayout closeModal={handleCloseModal} className="max-w-sm">
      <CompleteFastRegProfile onSuccessCallback={onSuccessHandler} />
    </ModalLayout>
  );
};

export default UserProfileCompletionModal;
