// LiveCasino is disabled for ZA, and AT if not authenticated
import { envIsCanada, envIsMexico, envIsNormal } from '@/constants/constants';
import { FluidKycStatus } from '@fluidpayments/types';

export const isLiveCasinoEnabled = (locale: string, isAuthenticated: boolean): boolean => {
  if (locale === 'at' && !isAuthenticated) {
    return false;
  }
  return locale !== 'za';
};

export const isSplashTechEnabled = (locale: string): boolean => {
  if (!envIsNormal()) {
    return false;
  }

  return ['ca', 'ca-fr'].includes(locale);
};

// For the given currency code, convert the amount to the jackpot amount, because we get the jackpot amounts usually in EUR
export const convertJackpotAmount = (currencyCode: string, amount: number) => {
  const MULTIPLIERS: Record<string, number> = {
    NOK: 10,
    ZAR: 16,
    THB: 40,
  };

  const currencyMultiplier = MULTIPLIERS[currencyCode] || 1;

  return amount * currencyMultiplier;
};

export const isCuracao = (locale: string): boolean => {
  return envIsNormal() && ['row', 'at', 'nz', 'za', 'ae', 'th'].includes(locale);
};

export const shouldDisplayFTDExitIntent = (
  depositCount: number | null,
  hasWelcomeBonuses: boolean,
  isFtdExitIntentShown: boolean,
  locale: string,
  kycStatus: FluidKycStatus | undefined,
): boolean => {
  if (depositCount !== null && depositCount > 0) {
    return false;
  }

  if (!hasWelcomeBonuses) {
    return false;
  }

  if (isFtdExitIntentShown) {
    return false;
  }

  if (envIsCanada() || envIsMexico() || isCuracao(locale)) {
    return kycStatus !== 'processing';
  }

  return true;
};
