import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

interface HeaderIconButtonProps {
  to?: string;
  onClick?: () => void;
  isScrolled: boolean;
  children: React.ReactNode;
  id?: string;
  ariaLabel?: string;
  text?: string;
  className?: string;
}

export function HeaderIconButton({
  to,
  onClick,
  isScrolled,
  children,
  id,
  ariaLabel,
  text,
  className,
}: HeaderIconButtonProps): JSX.Element {
  const css = classNames(
    'flex items-center justify-center text-slate-900 transition bg-slate-900/10 hover:bg-slate-900/20 hover:shadow-md',
    isScrolled
      ? 'md:text-slate-900 md:bg-slate-900/10 md:hover:bg-slate-900/20'
      : 'md:text-white md:bg-white/20 md:hover:bg-white/30',
    text ? 'rounded-3xl px-4' : 'rounded-full w-10 h-10',
    className,
  );

  if (to) {
    return (
      <Link to={to} className={css} id={id}>
        {children}
      </Link>
    );
  }

  return (
    <button type="button" aria-label={ariaLabel} className={css} onClick={onClick}>
      {text ? <span className="mr-2 text-sm hidden md:block">{text}</span> : null}
      {children}
    </button>
  );
}
