import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { StyledSwitch } from './Details';
import { selectUser } from '@/store/selectors/userSelectors';
import PromptMessage from '@/components/UI/PromptMessage';
import Spinner from '@/components/UI/Spinner';
import { AuthMethod, BffAuthenticationMethodResponse } from '@lucky7ventures/bff-types';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import BffApiService from '@/shared/bffApiService';

const Settings2FA = (): JSX.Element => {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const [authenticationMethod, setAuthenticationMethod] = useState<AuthMethod>(
    AuthMethod.OneFactor,
  );
  const { request: getAuthenticationMethodRequest } =
    useBffApiRequest<BffAuthenticationMethodResponse>();
  const {
    request: setAuthenticationMethodRequest,
    loading: setAuthLoading,
    success: setAuthSuccess,
  } = useBffApiRequest<null>();

  useEffect(() => {
    const handleOnSuccess = (method: BffAuthenticationMethodResponse) => {
      setAuthenticationMethod(method);
    };

    getAuthenticationMethodRequest({
      apiMethod: BffApiService.getAuthenticationMethod,
      successCallback: handleOnSuccess,
    });
  }, []);

  const submit = () => {
    setAuthenticationMethodRequest({
      apiMethod: BffApiService.setAuthenticationMethod,
      pathParams: authenticationMethod,
    });
  };

  const toggleTwoFactor = () => {
    setAuthenticationMethod(prevState =>
      prevState === AuthMethod.OneFactor ? AuthMethod.TwoFactorBySms : AuthMethod.OneFactor,
    );
  };

  return (
    <section className="rounded bg-white p-8">
      <h3 className="text-2xl font-bold">{intl.formatMessage({ id: 'settings.otp.title' })}</h3>
      <p className="my-6">{intl.formatMessage({ id: 'settings.otp.description' })}</p>
      <div className="mb-8 w-full">
        <div className="text-sm opacity-50">
          {intl.formatMessage({ id: 'settings.otp.registeredPhoneLabel' })}{' '}
          {`${user?.MobilePrefix} ${user?.Mobile}`}
        </div>
        <StyledSwitch htmlFor="twoFactorSms">
          {intl.formatMessage({ id: 'inputs.toggle.sms' })}
          <div className="switch">
            <input
              id="twoFactorSms"
              type="checkbox"
              checked={authenticationMethod === AuthMethod.TwoFactorBySms}
              onChange={toggleTwoFactor}
            />
            <span className="slider" />
          </div>
        </StyledSwitch>
      </div>
      {setAuthSuccess && (
        <div className="w-full">
          <PromptMessage
            type="success"
            message={intl.formatMessage({
              id: 'settings.details.successMessage',
            })}
          />
        </div>
      )}
      <div className="flex w-full justify-end">
        <button
          type="button"
          className="rounded-full bg-cyan-500 px-10 py-4 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg md:w-auto"
          onClick={submit}
        >
          {setAuthLoading ? (
            <Spinner color="white" height={22} width={22} borderwidth={3} />
          ) : (
            intl.formatMessage({ id: 'settings.details.saveButton' })
          )}
        </button>
      </div>
    </section>
  );
};

export default Settings2FA;
