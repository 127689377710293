import { PNP_DEPOSIT_AMOUNT_RULES } from '@/constants/paynplayConstants';
import { formatCurrency } from './currency-utils';

export const validatePnPAmount = (
  amount: number | string,
  currency: string,
  locale: string,
  isRequired = true,
): { isValid: boolean; message?: { id: string; values?: Record<string, string> | undefined } } => {
  if (amount === undefined || amount === null || amount === '') {
    return isRequired
      ? { isValid: false, message: { id: 'paynplay.error.amount' } }
      : { isValid: true };
  }

  amount = +amount;

  if (amount < PNP_DEPOSIT_AMOUNT_RULES.min) {
    return {
      isValid: false,
      message: {
        id: 'paynplay.error.min',
        values: {
          amount: formatCurrency(PNP_DEPOSIT_AMOUNT_RULES.min, locale, currency),
        },
      },
    };
  }

  if (amount > PNP_DEPOSIT_AMOUNT_RULES.max) {
    return {
      isValid: false,
      message: {
        id: 'paynplay.error.max',
        values: {
          amount: formatCurrency(PNP_DEPOSIT_AMOUNT_RULES.max, locale, currency),
        },
      },
    };
  }

  return { isValid: true };
};
