import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@/components/buttons/Button';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import CloseButton from '@/components/UI/Buttons/CloseButton';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { cdnImage } from '@/utils/image-utils';
import StorageManager from '@/shared/storageManager';

const RewardsWheelReminder = ({ closeModal }: { closeModal: () => void }) => {
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();

  const handleOnClose = () => {
    closeModal();
    triggerGTag(GTagEvents.rewards_wheel_reminder_close);
  };

  const handleCtaClick = () => {
    StorageManager.setRewardsReelReminder();
    triggerGTag(GTagEvents.rewards_wheel_reminder_cta);
    closeModal();
    navigate('/rewards-wheel');
  };

  useEffect(() => {
    triggerGTag(GTagEvents.rewards_wheel_reminder_shown);
    StorageManager.setRewardsReelReminder();
  }, []);

  return (
    <div className="relative rounded-xl overflow-hidden bg-rewardsWheelBg p-8">
      <CloseButton light left onClick={handleOnClose} />
      <img
        src={cdnImage('rewards-wheel/rewards-wheel-hero.png', {
          width: '350px',
          height: 'auto',
          dpr: '2',
        })}
        width="350px"
        alt={intl.formatMessage({ id: 'rewardReel.h1' })}
      />
      <div className="text-white max-w-sm flex flex-col items-center gap-4 mt-4">
        <div className="text-lg font-bold">
          {intl.formatMessage({ id: 'rewardsWheelReminder.title' })}
        </div>
        <div className="text-md">{intl.formatMessage({ id: 'rewardsWheelReminder.subtitle' })}</div>
        <Button
          onClickHandler={handleCtaClick}
          btnType="primary"
          text={intl.formatMessage({ id: 'rewardsWheelReminder.cta' })}
        />
      </div>
    </div>
  );
};

export default RewardsWheelReminder;
