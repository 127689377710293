import React from 'react';
import PropTypes from 'prop-types';
import propTypes from 'prop-types';

const LuckyDaysLogo = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 120 25" className="logo">
    <path
      d="M0,1 L0,24 L10.2,24 L10.2,20.5 L4,20.5 L4,1 L0,1 Z M32.7,0.6 C28.5,0.6 26.7,3.3 26.7,6.6 L26.7,18.4 C26.7,21.6 28.5,24.3 32.7,24.3 C36.7,24.3 38.6,21.6 38.6,18.3 L38.6,15.7 L34.6,15.7 L34.6,18.5 C34.6,20 34.1,20.8 32.6,20.8 C31.1,20.8 30.6,20 30.6,18.3 L30.6,6.6 C30.6,4.9 31.1,4.1 32.6,4.1 C33.7,4.1 34.6,4.9 34.6,6.8 L34.6,8.6 L38.6,8.6 L38.6,7 C38.6,3.6 36.6,0.7 32.6,0.7 M59.1,9.7 L56.3,1 L48.7,1 L44.5,9.6 L44.5,0.9 L40.5,0.9 L40.5,23.9 L44.5,23.9 L44.5,15.8 L45.8,13.3 L45.9,13.3 L49.1,24 L53.1,24 L48.4,9.5 L52.5,1.3 L57.2,14.4 L57.2,24 L61.2,24 L61.2,14.4 L65.7,0.9 L61.7,0.9 L59.1,9.7 Z M78,17.7 C78,20.1 77.2,20.9 75.1,20.9 L73.8,20.9 L73.8,4.3 L75,4.3 C77.1,4.3 77.9,5.1 77.9,7.6 L77.9,17.6 L78,17.7 Z M75.7,1 L69.8,1 L69.8,24 L75.7,24 C79.7,24 81.9,21.8 81.9,17 L81.9,8 C81.9,3.2 79.8,1 75.7,1 Z M88.4,15.5 L89.9,5.5 L91.6,15.5 L88.4,15.5 Z M87.5,1 L83.3,24 L87,24 L87.7,19 L92,19 L92.7,24 L96.6,24 L92.3,1 L87.4,1 L87.5,1 Z M104.1,1 L101.5,9.9 L98.5,1 L94.5,1 L99.3,14.5 L99.3,24 L103.3,24 L103.3,14.5 L108.2,1 L104.1,1 Z M111.6,6.5 C111.6,5 112.3,4.2 113.6,4.2 C114.5,4.2 115.6,4.8 115.7,6.8 L119.5,6.4 C119,3 117.2,0.8 114,0.8 C110,0.8 107.9,2.8 107.9,6.5 C107.9,13.1 115.9,14.3 115.9,18.9 C115.9,20.3 114.9,21 113.6,21 C112.3,21 111.4,19.9 111.2,17.5 L107.5,18.1 C107.6,21.5 109.7,24.5 113.5,24.5 C117.1,24.5 119.7,22.3 119.7,18.5 C119.7,12.3 111.7,10.5 111.7,6.5 M21.8,1 L19.8,1 L19.8,18.3 C19.8,20 19.1,20.8 17.7,20.8 C16.3,20.8 15.6,20 15.6,18.3 L15.6,1 L9.9,1 L9.9,5.4 L11.6,5.4 L11.6,18.3 C11.6,22.3 13.9,24.3 17.7,24.3 C21.5,24.3 23.7,22.3 23.7,18.3 L23.7,5.3 L25.2,5.3 L25.2,0.9 L21.8,0.9 L21.8,1 Z"
      fill={fill ? fill : 'currentColor'}
      fillRule="evenodd"
    />
  </svg>
);

LuckyDaysLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: propTypes.string,
};

LuckyDaysLogo.defaultProps = {
  width: 120,
  height: 25,
};

export default LuckyDaysLogo;
