import { Action } from 'redux';
import { BffUserKyc } from '@lucky7ventures/bff-types';

export enum KycActionType {
  GetDocuments = 'KYC_GET',
  GetDocumentsSuccess = 'KYC_GET_SUCCESS',
  GetDocumentsError = 'KYC_GET_ERROR',
  RunVerify = 'RUN_VERIFY',
  RunVerifyFinished = 'RUN_VERIFY_FINISHED',
  Reset = 'KYC_RESET',
}

export type KycGetDocumentsAction = Action<KycActionType.GetDocuments>;
export type KycGetDocumentsSuccessAction = Action<KycActionType.GetDocumentsSuccess> & {
  documents: BffUserKyc[];
};
export type KycGetDocumentsErrorAction = Action<KycActionType.GetDocumentsError>;

export type RunVerifyAction = Action<KycActionType.RunVerify>;
export type RunVerifyFinishedAction = Action<KycActionType.RunVerifyFinished>;

export type KycResetAction = Action<KycActionType.Reset>;

export type KycActions =
  | KycGetDocumentsAction
  | KycGetDocumentsSuccessAction
  | KycGetDocumentsErrorAction
  | KycResetAction
  | RunVerifyAction
  | RunVerifyFinishedAction;

export const kycGetDocuments = (): KycGetDocumentsAction => ({
  type: KycActionType.GetDocuments,
});

export const kycGetDocumentsSuccess = (documents: BffUserKyc[]): KycGetDocumentsSuccessAction => ({
  type: KycActionType.GetDocumentsSuccess,
  documents,
});

export const kycGetDocumentsError = (): KycGetDocumentsErrorAction => ({
  type: KycActionType.GetDocumentsError,
});

export const runVerify = (): RunVerifyAction => ({
  type: KycActionType.RunVerify,
});

export const runVerifyFinished = (): RunVerifyFinishedAction => ({
  type: KycActionType.RunVerifyFinished,
});

export const kycReset = (): KycResetAction => ({
  type: KycActionType.Reset,
});
