import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useBankIdProfile } from './useBankIdProfile';
import { IS_SWEDEN } from '@/constants/constants';
import { openModal } from '@/store/actions/modal';

export function useUpdateContactInfoToast(addToastCallback, cleanupToastCallback) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const TOAST_ID = 'UPDATE_USER_DETAILS_TOAST';
  const { isCompletedBankIdProfile } = useBankIdProfile();

  const openContactInfoModal = () => {
    IS_SWEDEN ? dispatch(openModal('contactInfoSweden')) : dispatch(openModal('contactInfo'));
  };

  useEffect(() => {
    if (isCompletedBankIdProfile) {
      cleanupToastCallback(TOAST_ID);
      return;
    }

    const toast = {
      id: TOAST_ID,
      order: 1,
      subtitle: intl.formatMessage({ id: 'notification.updateUserDetails' }),
      cta: intl.formatMessage({ id: 'misc.update' }),
      clickCallback: openContactInfoModal,
    };
    addToastCallback(toast);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompletedBankIdProfile]);
}
