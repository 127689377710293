import React from 'react';

import { useIntl } from 'react-intl';

import { TToastMessage } from './ToastList';

interface IToastProps {
  id: string;
  data: TToastMessage;
  toastIndex: number;
  isCollapsed: boolean;
}

export default function Toast({ id, data, toastIndex, isCollapsed }: IToastProps) {
  const intl = useIntl();

  const closeToast = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback?: () => void,
  ) => {
    event.stopPropagation();
    callback?.();
  };

  const handleMessageCallback = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    callback?: () => void,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    callback?.();
  };

  if (!data) {
    return null;
  }

  return (
    <div
      id={id}
      style={{
        width: isCollapsed && toastIndex > 0 ? `calc(100% - ${toastIndex}%)` : 'auto',
        left: isCollapsed && toastIndex > 0 ? `${toastIndex / 2}%` : '',
        bottom: isCollapsed && toastIndex > 0 ? `-${toastIndex * 10}px` : 0,
        zIndex: isCollapsed && toastIndex > 0 ? `-${toastIndex}` : 0,
      }}
      className={`toast mb-4 rounded-md bg-white shadow-[0_0_3px_#000] transition-all duration-200 ease-linear ${
        data.clickCallback ? 'cursor-pointer sm:hover:scale-105' : ''
      } ${data.type} ${
        isCollapsed && toastIndex > 0
          ? `absolute transition-none z-[${-toastIndex}]`
          : 'bottom-0 z-0'
      }`}
      onClick={event => handleMessageCallback(event, data.clickCallback)}
      key={toastIndex}
    >
      <div className="flex">
        <div
          className={`${
            isCollapsed && toastIndex > 0 ? 'max-h-[80px] overflow-hidden opacity-0' : ''
          } flex-2 flex flex-1 overflow-hidden py-3 px-4 text-sm [&_a]:text-cyan-500`}
        >
          {data.icon && <div className="mr-2">{data.icon}</div>}
          <div>
            <div className="font-bold">{data.title}</div>
            <div className="toast-subtitle">{data.subtitle}</div>
            {data.type === 'cta' && (
              <button
                data-cy="toast-cta"
                type="button"
                className="mt-2 cursor-pointer rounded-[50vh] border-0 bg-cyan-500 px-3 py-2 text-sm font-bold text-white hover:bg-cyan-800"
              >
                {data.cta}
              </button>
            )}
          </div>
        </div>
        {!data.type && (
          <div className="border-l-solid flex flex-col border-l-[1px] border-l-gray-300">
            {data.clickCallback && (
              <button
                data-cy="toast-cta"
                type="button"
                className="min-w-[96px] flex-1 cursor-pointer border-0 bg-none p-2 text-sm text-cyan-500 hover:text-cyan-800"
                onClick={event => handleMessageCallback(event, data.clickCallback)}
              >
                {data.cta}
              </button>
            )}
            {data.closeCallback && data.clickCallback && (
              <div className="border-t-solid h-[1px] border-t-[1px] border-t-gray-300" />
            )}
            {data.closeCallback && (
              <button
                data-cy="toast-cta"
                type="button"
                onClick={event => closeToast(event, data.closeCallback)}
                className="min-w-[96px] flex-1 cursor-pointer border-0 bg-none p-2 text-sm text-black"
              >
                {data.closeText
                  ? data.closeText
                  : intl.formatMessage({ id: 'notifications.closeButton' })}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
