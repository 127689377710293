import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { setLifetimeDeposits } from '@/store/actions/lastDepositActions';
import { selectIsPPConsented, selectIsTCConsented } from '@/store/selectors/consentsSelectors';
import { envIsMexico } from '@/constants/constants';
import BffApiService from '@/shared/bffApiService';
import { BffLifetimeDepositsResponse } from '@lucky7ventures/bff-types';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { isCuracao } from '@/utils/logic.utils';

export const useLifetimeDeposits = () => {
  const { request } = useBffApiRequest<BffLifetimeDepositsResponse>();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isTcConsented = useSelector(selectIsTCConsented);
  const isPpConsented = useSelector(selectIsPPConsented);
  const locale = useSelector(selectLocale);

  useEffect(() => {
    if (isAuthenticated && (envIsMexico() || isCuracao(locale)) && isTcConsented && isPpConsented) {
      const handleOnSuccess = (deposits: BffLifetimeDepositsResponse) => {
        dispatch(setLifetimeDeposits(deposits));
      };
      request({ apiMethod: BffApiService.getLifetimeDeposits, successCallback: handleOnSuccess });
    }
  }, [isAuthenticated, isTcConsented, isPpConsented, locale]);
};
