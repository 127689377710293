import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { BUILD_MODE } from '@/constants/constants';
import { selectUserId } from '@/store/selectors/userSelectors';

const storageKey = 'RG_TOAST_SHOWN';

export function useResponsibleGamingToast(
  addToastCallback: (message: any) => void,
  cleanupToastCallback: (key: string) => void,
): void {
  const userId = useSelector(selectUserId);
  const TOAST_ID = 'RESPONSIBLE_GAMING_TOAST';
  const intl = useIntl();
  const absoluteNavigate = useAbsoluteNavigate();

  const handleCtaClick = () => {
    if (userId) {
      addUserSeen(userId);
    }
    absoluteNavigate('/settings/responsible-gaming');
  };

  const handleCloseClick = () => {
    cleanupToastCallback(TOAST_ID);
    if (userId) {
      addUserSeen(userId);
    }
  };

  const addUserSeen = (userId: number): void => {
    const items = localStorage.getItem(storageKey);
    if (!items) {
      localStorage.setItem(storageKey, JSON.stringify([userId]));
      return;
    }

    const currentItems = JSON.parse(items);
    currentItems.push(userId);
    localStorage.setItem(storageKey, JSON.stringify(currentItems));
  };

  const hasUserSeenTheToast = (userId: number): boolean => {
    const items = localStorage.getItem(storageKey);
    if (!items) {
      return false;
    }

    const currentItems = JSON.parse(items);
    return currentItems.indexOf(userId) !== -1;
  };

  useEffect(() => {
    if (BUILD_MODE !== 'canada' || !userId || hasUserSeenTheToast(userId)) {
      cleanupToastCallback(TOAST_ID);
      return;
    }

    addToastCallback({
      id: TOAST_ID,
      order: 1,
      subtitle: intl.formatMessage({ id: 'toast.responsibleGaming.text' }),
      cta: intl.formatMessage({ id: 'toast.responsibleGaming.cta' }),
      clickCallback: handleCtaClick,
      closeCallback: handleCloseClick,
    });
  }, [userId]);
}
