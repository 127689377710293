import React, { useMemo } from 'react';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepData,
} from '@/store/authentication/AuthenticationStore';
import { FormattedMessage } from 'react-intl';
import LoginForm from '@/features/authentication/login/LoginForm';
import ForgotPasswordForm from '@/features/authentication/forgot-password/ForgotPasswordForm';
import TwoFactorAuthenticationForm from '@/features/authentication/two-factor-authentication/TwoFactorAuthenticationForm';
import ForgotPasswordSuccess from '@/features/authentication/forgot-password/ForgotPasswordSuccess';
import { useSelector } from 'react-redux';
import { selectAuthRecentlyLoggedOut } from '@/store/selectors/authSelectors';
import { useRewardsWheel } from '@/hooks/useRewardsWheel';
import Registration from '@/features/authentication/registration/Registration';

const HomeHeroAuthentication = () => {
  const { homeHeroAuthenticationStep } = useHomeHeroAuthenticationStepData();
  const hasRecentlyLoggedOut = useSelector(selectAuthRecentlyLoggedOut);
  const { isEnabled: isRewardsReelEnabled } = useRewardsWheel();

  const headerId = useMemo(() => {
    switch (homeHeroAuthenticationStep) {
      case HomeHeroAuthenticationStep.Login:
        return isRewardsReelEnabled && hasRecentlyLoggedOut // special handling for the state when user logs out and rewards reel is enabled
          ? 'authentication.login.header.rewardsWheelReminder'
          : 'authentication.login.header';
      case HomeHeroAuthenticationStep.ForgotPassword:
        return 'authentication.forgot-password.header';
      case HomeHeroAuthenticationStep.TwoFactorAuthentication:
        return 'authentication.two-factor-authentication.header';
      case HomeHeroAuthenticationStep.ForgotPasswordSuccess:
        return 'authentication.forgot-password-success.header';
      default:
        return 'fastReg.header';
    }
  }, [homeHeroAuthenticationStep, isRewardsReelEnabled, hasRecentlyLoggedOut]);

  const AuthenticationStep = useMemo(() => {
    switch (homeHeroAuthenticationStep) {
      case HomeHeroAuthenticationStep.Login:
        return LoginForm;
      case HomeHeroAuthenticationStep.ForgotPassword:
        return ForgotPasswordForm;
      case HomeHeroAuthenticationStep.TwoFactorAuthentication:
        return TwoFactorAuthenticationForm;
      case HomeHeroAuthenticationStep.ForgotPasswordSuccess:
        return ForgotPasswordSuccess;
      default:
        return Registration;
    }
  }, [homeHeroAuthenticationStep]);

  return (
    <div className="w-full max-w-[480px] relative text-center mb-8 mt-10 md:mt-24 sm:max-w-md px-[16px] md:px-0">
      <h1 className="!mb-6 text-white text-center sm:mx-4 text-2xl">
        <FormattedMessage
          id={headerId}
          values={{
            span: str => <span className="text-blue-blue">{str}</span>,
          }}
        />
      </h1>
      <div className="bg-white/5 rounded-lg backdrop-blur-sm shadow-md border-white/10 border-solid border-[1px] p-6 sm:p-8">
        <AuthenticationStep />
      </div>
    </div>
  );
};

export default HomeHeroAuthentication;
