import React from 'react';

import { useIntl } from 'react-intl';
import { BtnOneLineBlue } from '@/components/buttons/BtnOneLineBlue';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
} from '@/store/authentication/AuthenticationStore';

const ResetPasswordSuccess = () => {
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();
  const { setHomeHeroAuthenticationStep } = useHomeHeroAuthenticationStepActions();

  const handleOnLoginRedirect = () => {
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Login);
    navigate('/');
  };

  return (
    <>
      <div>
        <h2 className="text-lg text-white">
          {intl.formatMessage({
            id: 'reset.success.header',
          })}
        </h2>
        <p className="text-sm px-4 font-bold mb-10 mt-4">
          {intl.formatMessage({
            id: 'reset.success.text',
          })}
        </p>
      </div>
      <BtnOneLineBlue
        onClickHandler={handleOnLoginRedirect}
        text={intl.formatMessage({
          id: 'reset.success.buttonText',
        })}
      />
    </>
  );
};

export default ResetPasswordSuccess;
