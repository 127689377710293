import React, { FocusEvent, InputHTMLAttributes, useEffect, useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { useField } from 'formik';

import { classNames } from '@/utils/utils';

interface IFastRegistrationFormPasswordProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: string;
  onValidationError?: (error: string) => void;
}

const FastRegistrationFormPassword = ({
  label,
  onValidationError,
  ...rest
}: IFastRegistrationFormPasswordProps): JSX.Element => {
  const { name, className, onBlur } = rest;

  const [showPassword, setShowPassword] = useState(false);
  const [field, meta, helpers] = useField(name!);
  const { setValue } = helpers;

  const isEmptyValidationError = meta.touched && meta.error && field.value.length === 0;
  const isValidationError = meta.touched && meta.error && field.value.length > 0;

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    field.onBlur(event);
    onBlur?.(event);
    setValue(event.target.value.trim());
  };

  const toggleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  useEffect(() => {
    if (isValidationError) {
      onValidationError?.(meta.error!);
    }
  }, [isValidationError]);

  return (
    <div className="relative mb-3 w-full">
      <div className="w-full">
        <input
          {...field}
          {...rest}
          type={showPassword ? 'text' : 'password'}
          className={classNames(
            `w-full px-3.5 pt-6 bg-white/20 text-base text-white text-[15px] disabled:cursor-not-allowed disabled:text-gray-400
              rounded-md border-0 ring-0 focus:outline-0 focus:border-none focus:ring-0 autofill:!bg-none peer`,
            className,
            isValidationError ? 'rounded-b-none' : 'rounded-b-md',
            isEmptyValidationError ? 'ring-red-500 ring-1' : 'ring-0',
          )}
          id={name}
          onBlur={handleOnBlur}
        />
        <label
          className={classNames(
            `absolute left-2 top-0 px-2 transition-transform text-white text-base duration-150 pointer-events-none
            origin-top-left peer-focus:translate-y-1/3 peer-focus:scale-75 peer-autofill:text-black`,
            field.value.length > 0 ? 'translate-y-1/3 scale-75' : 'translate-y-[60%]',
          )}
        >
          {label}
        </label>
        <button
          type="button"
          className="absolute right-0 top-[50%] -translate-y-[50%] mr-2 peer-autofill:[&>svg]:text-black"
          onClick={toggleShowPassword}
        >
          {showPassword ? (
            <EyeSlashIcon className="h-5 w-5 text-white" />
          ) : (
            <EyeIcon className="h-5 w-5 text-white" />
          )}
        </button>
      </div>
      {isValidationError && (
        <div
          className="absolute bottom-0 translate-y-full left-0 right-0 bg-white/95 text-[10px] text-red-500 rounded-b-md px-1
          py-0.5 text-left"
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default FastRegistrationFormPassword;
