import React from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { BtnOneLineBlue } from '@/components/buttons/BtnOneLineBlue';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import ApiService from '../../../shared/apiService';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import {
  BasicRegistrationInfo,
  useEmailData,
  useRegistrationActions,
} from '@/store/registrationStore';
import { UserEmailExistenceResponseDto } from '@/models/apiV2/response/user-email-existence-response-dto.model';
import { openModal } from '@/store/actions/modal';
import Form from '@/components/form/Form';
import EmailField from '@/components/form/email/EmailField';

type FormValues = Pick<BasicRegistrationInfo, 'email'>;

const CanadaRegisterForm = () => {
  const { email } = useEmailData();
  const { updateField } = useRegistrationActions();
  const { request, loading } = useApiV2Request<UserEmailExistenceResponseDto>();
  const navigate = useAbsoluteNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    email,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'inputs.email.invalid' }))
      .notRequired(),
  });

  const handleOnSubmit = (values: FormValues) => {
    triggerGTag(GTagEvents.ont_reg_home_email_submit);

    const handleOnSuccess = ({ isExistingUser }: UserEmailExistenceResponseDto) => {
      if (isExistingUser) {
        triggerGTag(GTagEvents.ont_reg_existing_acc_show);
        dispatch(
          openModal('basicModal', {
            title: intl.formatMessage({ id: 'registration.ontario.existingUser.title' }),
            body: intl.formatMessage({ id: 'registration.ontario.existingUser.text' }),
            btnText: intl.formatMessage({ id: 'login.login' }),
            onBtnClick: () => {
              dispatch(openModal('login', values));
              triggerGTag(GTagEvents.ont_reg_existing_acc_login_click);
            },
            onClose: () => {
              triggerGTag(GTagEvents.ont_reg_existing_acc_close);
            },
          }),
        );
      } else {
        updateField('email', values.email);
        navigate('/register');
      }
    };

    if (values.email) {
      request(ApiService.checkUserEmailExistence, values, handleOnSuccess);
    } else {
      navigate('/register');
    }
  };

  return (
    <div
      className="bg-white/5 rounded-lg backdrop-blur-sm shadow-md border-white/10 border-solid border-[1px]
      max-w-md w-full mx-auto flex flex-col items-stretch gap-4 mt-8 p-6 sm:p-8"
    >
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
        noValidate
        className="flex flex-col justify-between gap-5"
      >
        {() => (
          <>
            <EmailField
              label={intl.formatMessage({ id: 'inputs.email' })}
              name="email"
              onFocus={() => triggerGTag(GTagEvents.ont_reg_home_email_click)}
              className="bg-white/20 border-0 focus:outline-0 focus:border-none focus:ring-0 autofill:!bg-none text-white"
            />
            <BtnOneLineBlue
              type="submit"
              text={intl.formatMessage({ id: 'registration.ontario.finish' })}
              isLoading={loading}
              className="min-h-fit"
            />
          </>
        )}
      </Form>
      <button
        type="button"
        className="opacity-80 text-sm font-bold inline-block cursor-pointer transition-opacity hover:opacity-100"
        onClick={e => {
          e.preventDefault();
          const bonusInfo = document.getElementById('bonus-info');
          if (bonusInfo !== null) {
            bonusInfo.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }
          triggerGTag(GTagEvents.more_info);
        }}
      >
        {intl.formatMessage({ id: 'hero.moreInfo' })}
      </button>
    </div>
  );
};

export default CanadaRegisterForm;
