import React from 'react';

import { useIntl } from 'react-intl';

import FlameIcon from '@/components/UI/Icons/FlameIcon';
import styled from 'styled-components';
import { SparklesIcon } from '@heroicons/react/24/solid';

const StyledGameTag = styled.span`
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  position: relative;
  border-radius: 6px;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 15px;
    margin-right: 4px;
    margin-bottom: 1px;
  }

  &.new {
    svg {
      color: ${props => props.theme.colors.yellow};
      margin-bottom: 1px;
    }
  }

  &.live {
    &:before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background: ${props => props.theme.colors.green};
      display: inline-block;
      margin-right: 6px;
      animation: pulse 2s linear infinite;
    }
  }

  &.hot {
    svg {
      color: ${props => props.theme.colors.red};
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

type GameTagProps = {
  type: 'live' | 'new' | 'hot' | 'jackpot';
  children?: React.ReactNode;
};

function GameTag({ type, children }: GameTagProps) {
  const intl = useIntl();

  switch (type) {
    case 'new':
      return (
        <StyledGameTag className="tag new">
          <SparklesIcon className="h-4 w-4" />
          {intl.formatMessage({ id: 'gameCard.new' })}
        </StyledGameTag>
      );
    case 'hot':
      return (
        <StyledGameTag className="tag hot">
          <FlameIcon />
          {intl.formatMessage({ id: 'gameCard.hot' })}
        </StyledGameTag>
      );
    case 'live':
      return (
        <StyledGameTag className="tag live">
          {intl.formatMessage({ id: 'gameCard.live' })}
        </StyledGameTag>
      );
    default:
      return <StyledGameTag className="tag jackpot">{children}</StyledGameTag>;
  }
}

export default GameTag;
