import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import PromptMessage from '@/components/UI/PromptMessage';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import BffApiService from '@/shared/bffApiService';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import PasswordField from '@/components/form/password/PasswordField';
import Form from '@/components/form/Form';
import { BtnOneLineBlue } from '@/components/buttons/BtnOneLineBlue';
import { openModal } from '@/store/actions/modal';
import { BffApiError } from '@/models/bff-api-error';
import { BffErrorCode } from '@lucky7ventures/bff-types';
import { generateBffErrorMessageWithCode } from '@/utils/utils';
import { generatePasswordValidation } from '../../../validators/password/password';

interface FormValues {
  password: string;
}

const initialValues: FormValues = {
  password: '',
};

const ResetPasswordForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const { recoveryCode } = useParams();
  const dispatch = useDispatch();
  const { request, loading } = useBffApiRequest<null>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    password: generatePasswordValidation({
      required: intl.formatMessage({ id: 'inputs.password.required' }),
      invalid: intl.formatMessage({ id: 'reset.password.invalid' }),
    }),
  });

  const handleOnSubmit = ({ password }: FormValues) => {
    triggerGTag(GTagEvents.reset_password_submit);
    if (!recoveryCode) {
      return;
    }

    const handleOnSuccess = () => {
      onSuccess();
      triggerGTag(GTagEvents.reset_password_success);
    };

    const handleOnError = (error: BffApiError) => {
      triggerGTag(GTagEvents.forgot_password_error, { error: error.code });
      switch (error.code) {
        case BffErrorCode.GIG_SAME_PASSWORD_AS_ONE_OF_THE_PREVIOUS_PASSWORDS:
          setErrorMessage(intl.formatMessage({ id: 'reset.alreadyUsedPassword' }));
          break;
        default:
          setErrorMessage(
            generateBffErrorMessageWithCode(
              intl.formatMessage({ id: 'error.support' }),
              error.code,
            ),
          );
      }
    };

    request({
      apiMethod: BffApiService.forgotPasswordChange,
      payload: { recoveryCode, password },
      successCallback: handleOnSuccess,
      errorCallback: handleOnError,
    });
  };

  return (
    <div className="flex flex-col items-center gap-4 [&>div:nth-child(2)]:mb-0">
      <p className="text-sm font-bold px-4">{intl.formatMessage({ id: 'reset.header' })}</p>
      <p className="m-0 p-0">{intl.formatMessage({ id: 'reset.subHeader' })}</p>
      {errorMessage && <PromptMessage type="error" message={errorMessage} />}
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        className="w-full flex flex-col gap-6"
      >
        {({ isValid }) => (
          <>
            <PasswordField
              name="password"
              label={intl.formatMessage({
                id: 'inputs.password',
              })}
              className="text-white border-[1px] border-transparent focus-within:border-blue-blue bg-white/20"
            />
            <BtnOneLineBlue
              type="submit"
              text={intl.formatMessage({ id: 'reset.submitButton' })}
              disabled={!isValid}
              isLoading={loading}
            />
          </>
        )}
      </Form>
      <p className="text-sm font-bold mt-auto">
        <FormattedMessage
          id="forgot.rememberedText"
          values={{
            a: str => (
              <span
                onClick={() => dispatch(openModal('login'))}
                className="underline text-blue-blue cursor-pointer"
              >
                {str}
              </span>
            ),
          }}
        />
      </p>
    </div>
  );
};

export default ResetPasswordForm;
