import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { selectUser } from '@/store/selectors/userSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '@/shared/utility';
import { User } from '@/models/user.model';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { openModal } from '@/store/actions/modal';

export function useStateUpdateToast(
  addToastCallback: (message: any) => void,
  cleanupToastCallback: (key: string) => void,
): void {
  const user = useSelector(selectUser);
  const TOAST_ID = 'STATE_UPDATE_TOAST';
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleCtaClick = () => {
    dispatch(openModal('updateState'));
  };

  const shouldPresentToast = (user: User | null): boolean => {
    if (isEmpty(user)) {
      return false;
    }

    // we want to show this toast only to canada users
    if (user?.CountryCode !== 'CA') {
      return false;
    }

    // ofc GiG is weird about it and they dont put null, rather they put this subdivision ID for a user that doesn't have subdivision set
    return user.Subdivision.SubdivisionId === '00000000-0000-0000-0000-000000000000';
  };

  useEffect(() => {
    if (!shouldPresentToast(user)) {
      cleanupToastCallback(TOAST_ID);
      return;
    }

    addToastCallback({
      id: TOAST_ID,
      order: 1,
      title: intl.formatMessage({ id: 'state.update.notification.header' }),
      subtitle: intl.formatMessage({ id: 'state.update.notification.text' }),
      type: 'cta',
      cta: intl.formatMessage({ id: 'misc.update' }),
      icon: <ExclamationTriangleIcon className="h-6 w-6" />,
      clickCallback: handleCtaClick,
    });
  }, [user]);
}
