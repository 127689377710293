import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import Toast from './Toast';
import { CollapseIcon } from './UI/Icons/CollapseIcon';
import useWindowDimensions from '@/hooks/useWindowDimensions';

export type TToastMessage = {
  id: string;
  clickCallback?: () => void;
  closeCallback?: () => void;
  order: number;
  icon: React.ReactNode;
  type: string;
  title?: string;
  subtitle?: React.ReactNode;
  cta: string;
  closeText?: string;
};

export function ToastList({ messages }: { messages: TToastMessage[] }) {
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    setIsMobile(width < 767);
  }, [width]);

  if (!messages) {
    return null;
  }

  const expandList = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isCollapsed) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    setIsCollapsed(false);
  };

  const collapseList = () => {
    setIsCollapsed(true);
  };

  const expandListHeight = (messagesNumber: number) => 80 + (messagesNumber - 1) * 10;
  const expandedListHeight = expandListHeight(messages.length);

  return (
    <div className="flex flex-col items-center">
      {!isCollapsed && isMobile && (
        <div className="flex w-[350px] justify-end">
          <button
            type="button"
            onClick={() => collapseList()}
            className="mb-4 flex cursor-pointer items-center border-0 bg-transparent text-sm text-white [&_svg]:!ml-1 [&_svg]:!h-4"
          >
            {intl.formatMessage({ id: 'notifications.showLess' })} <CollapseIcon />
          </button>
        </div>
      )}
      <div className="relative w-[350px]">
        {messages
          .sort((a, b) => a.order - b.order)
          .map((message, i) => (
            <Toast
              id={message.id}
              data={message}
              toastIndex={i}
              isCollapsed={isCollapsed && isMobile}
              key={message.id}
            />
          ))}
        {isCollapsed && messages.length > 1 && (
          <div
            aria-hidden="true"
            style={{
              height: `${expandedListHeight}px`,
            }}
            className={`absolute top-0 right-0 left-0 block cursor-pointer sm:hidden`}
            onClick={event => expandList(event)}
          >
            &nbsp;
          </div>
        )}
      </div>
    </div>
  );
}
