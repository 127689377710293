import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { PayNPlaySubmitButton } from './PayNPlaySubmitButton';
import { PnPAmountSelect } from './PnPAmountSelect';
import { PayNPlayLoginSwitch } from './PayNPlayLoginSwitch';
import { PayNPlayInput } from './PayNPlayInput';
import { PayNPlayWelcomeOptIn } from './PayNPlayWelcomeOptIn';
import { PayNPlayTCs } from './PayNPlayTCs';
import { PNP_CURRENCY, PNP_DEPOSIT_DEFAULTS } from '@/constants/paynplayConstants';
import { validatePnPAmount } from '@/utils/paynplayValidators';
import { mapLocaleToLang } from '@/utils/utils';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { TranslatedRichText } from '@/components/TranslatedRichText';
import { envIsSweden } from '@/constants/constants';
import { openModal } from '@/store/actions/modal';

export function PayNPlayHome(): JSX.Element {
  const [depositAmount, setDepositAmount] = useState<number | string>('');
  const [inputError, setInputError] = useState<string | null>(null);
  const [welcomeBonus, setWelcomeBonus] = useState(true);
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const intl = useIntl();
  const alreadyOptedIn = localStorage.getItem('PNP_WCB_OPTIN') === 'true';

  const registerWithBankId = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = validatePnPAmount(
      depositAmount,
      PNP_CURRENCY,
      mapLocaleToLang(locale),
      true,
    );

    if (!validation.isValid) {
      setInputError(
        intl.formatMessage({ id: validation?.message?.id }, validation?.message?.values),
      );
      return;
    }

    dispatch(openModal('paynplayIframe', { amount: depositAmount, welcomeBonus: welcomeBonus }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10) || '';
    if (newValue === null) {
      setInputError(null);
    }
    setDepositAmount(newValue);
  };

  const handleAmountSelect = (value: number) => {
    setInputError(null);
    setDepositAmount(value);
  };

  const loginWithoutDeposit = (): void => {
    dispatch(openModal('paynplayIframe', { amount: 0, login: true }));
  };

  return (
    <div className="relative flex min-h-[300px] w-full max-w-[400px]">
      <div className="relative flex w-full flex-col overflow-hidden rounded-2xl sm:bg-white sm:shadow-lg">
        <form className="flex w-full flex-col space-y-6 sm:p-6" onSubmit={registerWithBankId}>
          <div>
            <TranslatedRichText
              id="hero.header"
              tagName="h1"
              className="m-0 mx-auto max-w-[310px] text-3xl font-bold leading-tight text-white sm:m-0 sm:hidden sm:max-w-full sm:text-2xl sm:text-black"
            />
            <h3 className="m-0 mx-auto hidden max-w-[260px] text-3xl font-bold leading-tight text-white sm:block sm:text-2xl sm:text-black">
              {intl.formatMessage({ id: 'paynplay.modal.header' })}
            </h3>
            <p className="text-md mt-3 mb-0 hidden font-bold text-gray-500 sm:block">
              {intl.formatMessage({ id: 'paynplay.modal.subtitle' })}
            </p>
            <p className="text-md mx-auto mt-3 mb-0 w-full text-gray-200 sm:hidden">
              <FormattedMessage
                id="hero.tagline2"
                values={{
                  b: str => <span className="font-bold">{str}</span>,
                }}
              />
            </p>
          </div>
          <PayNPlayInput onChangeHandler={handleChange} error={inputError} value={depositAmount} />
          <PnPAmountSelect
            depositAmount={depositAmount || ''}
            handleAmountSelect={handleAmountSelect}
            low={PNP_DEPOSIT_DEFAULTS.low}
            mid={PNP_DEPOSIT_DEFAULTS.mid}
            high={PNP_DEPOSIT_DEFAULTS.high}
          />
          <PayNPlaySubmitButton />
          {envIsSweden() && !alreadyOptedIn && (
            <div className="text-white sm:text-gray-600">
              <PayNPlayWelcomeOptIn
                id="wcbOptInHome"
                optIn={welcomeBonus}
                toggleOptIn={() => setWelcomeBonus(prevValue => !prevValue)}
              />
            </div>
          )}
          <div className="sm:hidden">
            <p className="m-0 text-white">
              {intl.formatMessage({ id: 'paynplay.modal.login.text' })}
            </p>
            <button
              type="button"
              onClick={loginWithoutDeposit}
              className="inline-flex w-full items-center justify-center font-bold text-white underline"
            >
              {intl.formatMessage({ id: 'paynplay.modal.login.link' })}
              <ArrowRightIcon className="ml-1 inline h-3 w-3" />
            </button>
          </div>
          <div className="prose prose-sm italic prose-p:text-white prose-a:text-white sm:prose-p:text-gray-600 sm:prose-a:text-gray-800">
            <PayNPlayTCs />
          </div>
        </form>
        <div className="hidden sm:block">
          <PayNPlayLoginSwitch onClickHandler={loginWithoutDeposit} />
        </div>
      </div>
    </div>
  );
}
