import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@/components/buttons/Button';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
} from '@/store/authentication/AuthenticationStore';

const ForgotPasswordSuccess = () => {
  const intl = useIntl();

  const { setHomeHeroAuthenticationStep } = useHomeHeroAuthenticationStepActions();

  const handleSwitchAuthMethod = () =>
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Login);

  return (
    <>
      <p className="mb-6 leading-snug text-white text-justify">
        {intl.formatMessage({ id: 'authentication.forgot-password-success.description' })}
      </p>
      <Button
        onClickHandler={handleSwitchAuthMethod}
        text={intl.formatMessage({ id: 'authentication.forgot-password-success.button' })}
        type="button"
        btnType="primary"
        data-cy="forgot-password-success-button"
      />
    </>
  );
};

export default ForgotPasswordSuccess;
