import React from 'react';

import { useIntl } from 'react-intl';

import { TranslatedRichText } from '@/components/TranslatedRichText';
import CanadaRegisterForm from './CanadaRegisterForm';
import { useDispatch } from 'react-redux';
import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '@/constants/constants';
import { openModal } from '@/store/actions/modal';

const HomeHeroContentPublicCanada = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <div className="text-white p-[16px]">
      <TranslatedRichText
        id="hero.header"
        tagName="h1"
        className="text-4xl md:text-6xl font-bold mb-6 font-display text-white text-center"
      />
      <TranslatedRichText
        id="hero.text.ontario"
        className="text-center text-xl leading-8"
        tagName="p"
      />
      <div className="mt-4 max-w-5xl text-center text-sm">
        <span>{intl.formatMessage({ id: 'hero.text.ontario.termsAndConditions' })}</span>
        <button
          type="button"
          onClick={() => dispatch(openModal('bonusTermsAndConditions'))}
          className="bold italic underline"
        >
          {intl.formatMessage({ id: 'hero.text.ontario.termsAndConditionsButton' })}
        </button>
      </div>
      <CanadaRegisterForm />
      <div className="mt-4">
        <img
          src={`${CDN_BASE}/cdn-cgi/image/fit=cover,width=100,height=auto,format=auto,dpr=2${CDN_IMAGES_COMMON_PATH}/footer/igamingontario.png`}
          alt="iGaming Ontario"
          className="mx-auto"
        />
      </div>
    </div>
  );
};

export default HomeHeroContentPublicCanada;
