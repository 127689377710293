import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import {
  selectAmlVerificationStatus,
  selectAmlVerificationStatusError,
} from '@/store/selectors/amlSelectors';

const TOAST_ID = 'AML_VERIFICATION_TOAST';

export function useAmlVerificationToast(addToastCallback, cleanupToastCallback) {
  const intl = useIntl();
  const absoluteNavigate = useAbsoluteNavigate();
  const amlVerificationStatus = useSelector(selectAmlVerificationStatus);
  const amlVerificationStatusError = useSelector(selectAmlVerificationStatusError);

  const handleCtaClick = () => {
    absoluteNavigate('/settings/verify');
  };

  useEffect(() => {
    if (!amlVerificationStatus || amlVerificationStatusError) {
      cleanupToastCallback(TOAST_ID);
      return;
    }

    if (amlVerificationStatus !== 0) {
      const toast = {
        id: TOAST_ID,
        order: 1,
        subtitle: intl.formatMessage({ id: 'amlToast.text' }),
        cta: intl.formatMessage({ id: 'amlToast.cta' }),
        clickCallback: handleCtaClick,
        closeCallback: () => cleanupToastCallback(TOAST_ID),
      };
      addToastCallback(toast);
    } else {
      cleanupToastCallback(TOAST_ID);
    }
  }, [amlVerificationStatus, amlVerificationStatusError]);
}
