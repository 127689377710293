import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { TransactionType, useFluidOpen, useFluidOpenWithBonus } from './fluid/fluidPaymentsStore';
import { useCallback } from 'react';

interface CashierState {
  cashierIsShown: boolean;
  depositTabEffectTrigger: boolean;
  toggleDepositTabEffectTrigger: () => void;
  showCashier: () => void;
  hideCashier: () => void;
  cashierTransition: number;
  increaseCashierTransition: () => void;
}

export const useCashierStore = create<CashierState>(set => {
  return {
    depositTabEffectTrigger: false,
    cashierIsShown: false,
    cashierTransition: 0,
    showCashier: () => {
      set(state => ({ ...state, cashierIsShown: true }));
    },
    hideCashier: () => {
      set(state => ({ ...state, cashierIsShown: false }));
    },
    toggleDepositTabEffectTrigger: () => {
      set(state => ({ ...state, depositTabEffectTrigger: !state.depositTabEffectTrigger }));
    },
    increaseCashierTransition: () => {
      set(state => ({ ...state, cashierTransition: state.cashierTransition + 1 }));
    },
  };
});

export const useCashierShown = () => {
  const { cashierIsShown } = useCashierStore(
    state => ({
      cashierIsShown: state.cashierIsShown,
    }),
    shallow,
  );

  return cashierIsShown;
};

export const useCashierShow = () => {
  const { show } = useCashierStore(
    state => ({
      show: state.showCashier,
    }),
    shallow,
  );
  const openFluid = useFluidOpen();
  const openFluidWithBonus = useFluidOpenWithBonus();

  return useCallback(
    (options?: { bonusCode?: string; transactionType?: TransactionType }) => {
      if (options && options.bonusCode) {
        openFluidWithBonus(options.bonusCode);
      } else {
        openFluid(options?.transactionType);
      }

      show();
    },
    [show, openFluid, openFluidWithBonus],
  );
};

export const useCashierHide = () => {
  const { hide } = useCashierStore(
    state => ({
      hide: state.hideCashier,
    }),
    shallow,
  );

  return hide;
};

export const useDepositTabEffect = () => {
  const { depositTabEffectTrigger, toggleDepositTabEffectTrigger } = useCashierStore(
    state => ({
      depositTabEffectTrigger: state.depositTabEffectTrigger,
      toggleDepositTabEffectTrigger: state.toggleDepositTabEffectTrigger,
    }),
    shallow,
  );

  return { depositTabEffectTrigger, toggleDepositTabEffectTrigger };
};

export const useCashierIncreaseTransition = () => {
  const { increaseCashierTransition } = useCashierStore(
    state => ({
      increaseCashierTransition: state.increaseCashierTransition,
    }),
    shallow,
  );

  return increaseCashierTransition;
};

export const useCashierTransition = () => {
  const { cashierTransition } = useCashierStore(
    state => ({
      cashierTransition: state.cashierTransition,
    }),
    shallow,
  );

  return cashierTransition;
};
