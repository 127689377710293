import { useDispatch, useSelector } from 'react-redux';
import { useRewardsWheel } from '@/hooks/useRewardsWheel';
import { useEffect } from 'react';
import { openModal } from '@/store/actions/modal';
import { selectAuthIsRegistration, selectIsAuthenticated } from '@/store/selectors/authSelectors';
import StorageManager from '@/shared/storageManager';
import { DateTime } from 'luxon';

const has24HoursPassedSinceLastReminder = (): boolean => {
  const lastReminder = StorageManager.getRewardsWheelReminder();
  if (!lastReminder) {
    // if there is no last reminder in local storage we treat it as if it has been 24 hours
    return true;
  }
  const lastReminderTime = DateTime.fromISO(lastReminder, { zone: 'utc' });
  const now = DateTime.utc();

  return now.diff(lastReminderTime, 'hours').hours >= 24;
};

export function useRewardsWheelReminder(): void {
  const { isEnabled: isRewardReelEnabled } = useRewardsWheel();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isRegistration = useSelector(selectAuthIsRegistration);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isRewardReelEnabled &&
      !isRegistration &&
      isAuthenticated &&
      has24HoursPassedSinceLastReminder()
    ) {
      // Order 100 will put this modal at the back of the modal queue
      dispatch(openModal('rewardsWheelReminder', {}, 100));
    }
  }, [isRewardReelEnabled, isAuthenticated, isRegistration]);
}
