export const LD_CLOSED_TOASTS = 'LD_CLOSED_NOTIFICATIONS';

export const saveClosedToasts = data => {
  localStorage.setItem(LD_CLOSED_TOASTS, JSON.stringify(data));
};

export const getClosedToasts = () => {
  const localStorageValue = localStorage.getItem(LD_CLOSED_TOASTS);
  const savedClosedToasts = JSON.parse(localStorageValue);
  const closedToasts = savedClosedToasts || [];
  // prune the expired && re-save
  const now = new Date();
  const prunedClosedToasts = closedToasts.filter(t => new Date(t.expireOn) > now);
  saveClosedToasts(prunedClosedToasts);
  return prunedClosedToasts;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveClosedToast = (id, renewInDays = 7) => {
  const closedToasts = getClosedToasts();
  const expireOn = new Date();
  expireOn.setDate(expireOn.getDate() + renewInDays);
  const toSave = [...closedToasts, { id, expireOn }];
  saveClosedToasts(toSave);
};

export const getNonExpiredClosedToasts = () => getClosedToasts().map(i => i.id);
