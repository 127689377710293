import React, { useState } from 'react';

import LuckyDaysLogo from '@/components/UI/Logos/LuckyDaysLogo';
import ResetPasswordForm from '@/features/authentication/reset-password/ResetPasswordForm';
import { cdnImage } from '@/utils/image-utils';
import ResetPasswordSuccess from '@/features/authentication/reset-password/ResetPasswordSuccess';

const BG_LARGE = cdnImage('luckydays-beach2.jpg', { width: '1440', height: '900' });
const BG_MEDIUM = cdnImage('luckydays-beach2.jpg', { width: '1024', height: '900' });
const BG_SMALL = cdnImage('luckydays-beach2.jpg', { width: '640', height: '900' });

const ResetPassword = () => {
  const [isSuccess, setIsSuccess] = useState(false);

  const handleOnSuccess = () => {
    setIsSuccess(true);
  };

  return (
    <div className="h-full min-h-screen flex justify-center py-20 px-5 relative sm:items-center sm:px-10">
      <div
        className="absolute left-0 right-0 bottom-0 top-0 after:absolute after:right-0 after:left-0
        after:bottom-0 after:top-0 after:bg-black/50 z-0"
      >
        <img
          className="w-full h-full object-cover object-right-bottom"
          src={BG_LARGE}
          srcSet={`${BG_SMALL} 640w, ${BG_MEDIUM} 1024w, ${BG_LARGE} 1440w`}
          alt="Lucky the cat on his way to the beach"
        />
      </div>
      <div
        className="flex flex-col flex-wrap gap-6 w-full max-w-[380px] overflow-hidden rounded-md bg-white/20
        relative pt-12 px-8 pb-8 justify-center items-center text-center h-fit backdrop-blur-md text-white
        border-white/10 border-solid border-[1px]"
      >
        <div className="text-center">
          <LuckyDaysLogo fill="white" />
        </div>
        {!isSuccess ? <ResetPasswordForm onSuccess={handleOnSuccess} /> : <ResetPasswordSuccess />}
      </div>
    </div>
  );
};

export default ResetPassword;
