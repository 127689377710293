import React, { useCallback, useEffect, useMemo } from 'react';

import DetachedDropdown, {
  detachedDropdownFallbackValue,
} from '@/components/form/dropdown/DetachedDropdown';
import {
  createMexicoGameFilterOptions,
  GIG_GAMES_CATEGORIES_RECORD,
  MEXICO_FEATURES_OPTIONS,
  MEXICO_FEATURES_RECORD,
  MEXICO_PROVIDERS_OPTIONS,
  MEXICO_PROVIDERS_RECORD,
  useGameCategoryNavActions,
  useGameCategoryNavData,
} from '@/store/mexico/gamesCategoryNavStore';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { useSelector } from 'react-redux';
import { selectGamesCategories, selectGamesProviders } from '@/store/selectors/gamesSelectors';
import { AbsoluteNavLink } from '@/components/AbsoluteNavLink';
import { HomeIcon } from '@heroicons/react/24/outline';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useLocation } from 'react-use';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { IS_HALLOWEEN } from '@/constants/constants';

const initialProvider = {
  label: 'Estudio',
  value: detachedDropdownFallbackValue,
};

const initialFeature = {
  label: 'Tipo de juego',
  value: detachedDropdownFallbackValue,
};

const initialCategory = {
  label: 'Categoría',
  value: detachedDropdownFallbackValue,
};

const noneSelected = { value: detachedDropdownFallbackValue, label: 'Ninguno' };

const mexicoMetadataGamesFeaturesCategories = Object.keys(MEXICO_FEATURES_RECORD);
const mexicoMetadataGamesProvidersCategories = Object.keys(MEXICO_PROVIDERS_RECORD);

function GameCategoryNavMexico(): JSX.Element {
  const { feature, provider, category } = useGameCategoryNavData();
  const { setFeature, setProvider, setCategory, reset } = useGameCategoryNavActions();
  const categories = useSelector(selectGamesCategories);
  const providers = useSelector(selectGamesProviders);
  const navigate = useAbsoluteNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const intl = useIntl();
  const { reset: resetGameCategoryNavStore } = useGameCategoryNavActions();

  const gameCategories = useMemo(() => {
    let categories: { label: string; value: string }[] = [];

    if (isAuthenticated)
      categories.push({
        label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD['last-played'] }),
        value: 'last-played',
      });

    categories.push({
      label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD['recommended'] }),
      value: 'recommended',
    });

    if (IS_HALLOWEEN) {
      categories.push({
        label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD['halloween'] }),
        value: 'halloween',
      });
    }

    categories.push({
      label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD['new-games'] }),
      value: 'new-games',
    });

    categories.push({
      label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD['live-casino'] }),
      value: 'live-casino',
    });

    categories.push({
      label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD['slots'] }),
      value: 'slots',
    });

    categories.push({
      label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD['jackpots'] }),
      value: 'jackpots',
    });

    categories.push({
      label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD['table-games'] }),
      value: 'table-games',
    });

    categories.push({
      label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD['all-games'] }),
      value: 'all-games',
    });

    return categories;
  }, [isAuthenticated, IS_HALLOWEEN]);

  const featuresDropdownOptions = useMemo(
    () =>
      createMexicoGameFilterOptions(MEXICO_FEATURES_OPTIONS)
        .filter(category => Object.keys(categories).includes(category.value))
        .concat([noneSelected]),
    [categories],
  );
  const providersDropdownOptions = useMemo(
    () =>
      createMexicoGameFilterOptions(MEXICO_PROVIDERS_OPTIONS)
        .filter(provider => Object.keys(providers).includes(provider.value))
        .concat([noneSelected]),
    [providers],
  );

  const handleCategoryChange = useCallback(
    change => {
      navigate('/games/' + change);
      setCategory(change);
    },
    [setCategory, navigate],
  );

  const handleFeatureChange = useCallback(
    change => {
      if (change !== detachedDropdownFallbackValue) {
        navigate('/games/' + change);
        setFeature(change);
      } else {
        setFeature(undefined);
        navigate('/games');
      }
    },
    [setFeature, navigate],
  );

  const handleProviderChange = useCallback(
    change => {
      if (change !== detachedDropdownFallbackValue) {
        navigate('/games/' + change);
        setProvider(change);
      } else {
        setProvider(undefined);
        navigate('/games');
      }
    },
    [setProvider, navigate],
  );

  useEffect(() => {
    const slug = location?.pathname?.split('/')[2];
    if (slug && mexicoMetadataGamesFeaturesCategories.includes(slug)) {
      setFeature(slug);
    } else if (slug && mexicoMetadataGamesProvidersCategories.includes(slug)) {
      setProvider(slug);
    }

    return () => resetGameCategoryNavStore();
  }, [location, resetGameCategoryNavStore]);

  return (
    <div
      className={classNames('sticky z-[90] w-full shadow-md', {
        'top-[55px] md:top-16': !isAuthenticated,
        'top-[-1px] md:top-16': isAuthenticated,
      })}
    >
      <div className="flex overflow-y-visible overflow-x-auto items-center no-scrollbar sm:justify-center gap-x-2 bg-white p-3">
        <AbsoluteNavLink
          to="/games"
          onClick={() => reset()}
          className={classNames(
            'flex flex-shrink-0 gap-x-2 items-center font-bold rounded-3xl py-1.5 px-3',
            {
              'bg-blue-blue text-white': location.pathname === '/games',
              'bg-gray-100 text-black': location.pathname !== '/games',
            },
          )}
        >
          <HomeIcon width={15} height={15} />
          {intl.formatMessage({ id: 'games.categories.lobby' })}
        </AbsoluteNavLink>
        <div className="2xl:hidden">
          <DetachedDropdown
            data-cy="mexico-category-dropdown"
            label="Categorías"
            options={gameCategories}
            value={
              category
                ? {
                    value: category,
                    label: intl.formatMessage({ id: GIG_GAMES_CATEGORIES_RECORD[category] }),
                  }
                : initialCategory
            }
            onChange={handleCategoryChange}
          />
        </div>
        <div className="hidden 2xl:flex 2xl:gap-x-2">
          {gameCategories.map(category => (
            <AbsoluteNavLink
              key={category.value + category.label}
              to={'/games/' + category.value}
              onClick={() => reset()}
              className={classNames(
                'flex flex-shrink-0 gap-x-2 items-center cursor-pointer font-bold rounded-3xl py-1.5 px-3',
                {
                  'bg-blue-blue text-white': location.pathname?.includes(category.value),
                  'bg-gray-100 text-black': !location.pathname?.includes(category.value),
                },
              )}
            >
              {category.label}
            </AbsoluteNavLink>
          ))}
        </div>
        <DetachedDropdown
          label="Tipo de juego"
          options={featuresDropdownOptions}
          value={
            feature ? { value: feature, label: MEXICO_FEATURES_RECORD[feature] } : initialFeature
          }
          onChange={handleFeatureChange}
        />
        <DetachedDropdown
          label="Estudio"
          options={providersDropdownOptions}
          value={
            provider
              ? { value: provider, label: MEXICO_PROVIDERS_RECORD[provider] }
              : initialProvider
          }
          onChange={handleProviderChange}
        />
      </div>
    </div>
  );
}

export default GameCategoryNavMexico;
