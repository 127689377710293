import { RootState } from '@/models/root-state.model';
import { FrontEndUserBonusObject } from '@lucky7ventures/lucky-components';
import { createSelector } from 'reselect';
import { ExtendedActiveBonusModel, ExtendedBonusModel } from '@/models/extended-bonus.model';
import { selectBonusesContent } from '@/store/selectors/bonusesContentSelector';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { FluidBonusData } from '@fluidpayments/types';
import { bonusFluidImage } from '@/utils/image-utils';
import {
  BffAvailableBonus,
  BffAvailableBonusesResponse,
  BonusRedeemType,
} from '@lucky7ventures/bff-types';

export const selectAvailableBonuses = (state: RootState): BffAvailableBonusesResponse =>
  state.bonuses.availableBonuses;
export const selectActiveBonuses = (state: RootState): FrontEndUserBonusObject[] =>
  state.bonuses.bonuses;

export const selectAvailableBonusesFetched = (state: RootState): boolean =>
  state.bonuses.availableBonusesFetched;

export const selectAvailableBonusesIds = createSelector(
  selectAvailableBonuses,
  bonuses => bonuses?.map(i => i.bonusId),
);
export const selectActiveBonusesIds = createSelector(selectActiveBonuses, bonuses =>
  bonuses.map(i => i.BonusId),
);

export const selectAvailableBonusesWithContent = createSelector(
  [selectAvailableBonuses, selectBonusesContent, selectLocale],
  (bonuses, content, locale): ExtendedBonusModel[] =>
    bonuses &&
    bonuses
      .filter(bonus => {
        if (content[locale] && content[locale][bonus.bonusId] === null) {
          return false;
        }
        if (content[locale] && !!content[locale][bonus.bonusId]) {
          return !!content[locale][bonus.bonusId];
        }
        return false;
      })
      .map((bonus): ExtendedBonusModel => {
        return {
          ...bonus,
          content: content[locale][bonus.bonusId]!,
        };
      })
      .sort((bonusA, bonusB) => bonusA.bonusId - bonusB.bonusId),
);

export const selectActiveBonusesWithContent = createSelector(
  [selectActiveBonuses, selectBonusesContent, selectLocale],
  (bonuses, content, locale): ExtendedActiveBonusModel[] =>
    bonuses.map(bonus => {
      return {
        ...bonus,
        Content:
          content[locale] && content[locale][bonus.BonusId] ? content[locale][bonus.BonusId] : null,
      };
    }),
);

export const selectCashierBonuses = createSelector(
  [selectAvailableBonuses, selectBonusesContent, selectLocale],
  (availableBonuses, content, locale) => {
    const bonusTitle = (bonus: BffAvailableBonus): string => {
      const localeContent = content[locale];
      const bonusContent = localeContent ? content[locale][bonus.bonusId] : null;
      if (bonusContent) {
        return bonusContent.name;
      }
      return bonus.name;
    };

    const bonusDescription = (bonus: BffAvailableBonus): string => {
      const localeContent = content[locale];
      const bonusContent = localeContent ? content[locale][bonus.bonusId] : null;
      if (bonusContent) {
        return bonusContent.description;
      }
      return bonus.description;
    };

    return availableBonuses
      .filter(bonus => bonus.redeemTypeId !== BonusRedeemType.BonusCode)
      .map(bonus => ({
        bonusAmount: {},
        code: bonus.promoCode ?? '',
        title: { en: bonusTitle(bonus) },
        desc: { en: bonusDescription(bonus) },
        fixed: false,
        id: bonus.bonusId.toString(),
        isCodeOnly: true,
        minAmount: {},
        multiplier: 0,
        typeId: bonus.typeId,
      }));
  },
);

export const defineMaxBonusPerLocale = (maxBonusAmount: number, locale: string): number => {
  /*
   * GiG sends max bonus amount based in Euro currency, which is
   * inaccurate for Mexico, Norway, South Africa and Thailand.
   * Hence we need to do manual multiplier conversion from Euro
   * to those countries' currencies:
   * MXN = multiplier by 20
   * NOK = multiplier by 10
   * ZAR  = multiplier by 16
   * THB = multiplier by 35
   * */

  switch (locale) {
    case 'mx':
      return maxBonusAmount * 20;
    case 'no':
      return maxBonusAmount * 10;
    case 'za':
      return maxBonusAmount * 16;
    case 'th':
      return maxBonusAmount * 35;
    default:
      return maxBonusAmount;
  }
};

export const selectFluidCashierBonuses = createSelector(
  selectAvailableBonuses,
  selectAvailableBonusesWithContent,
  selectLocale,
  (bonuses, bonusesWithContent, locale): FluidBonusData => {
    return bonuses
      .filter(bonus => bonus.redeemTypeId !== BonusRedeemType.BonusCode && bonus.promoCode) // There was a bug in Fluid that if we pass a bonus that doesn't have a promo code, the fluid cashier would crash
      .map(bonus => {
        const content = bonusesWithContent.find(
          bonusWithContent => bonusWithContent.bonusId === bonus.bonusId,
        )?.content;

        return {
          code: bonus.promoCode!,
          selected: false,
          title: content && content.name ? content.name : bonus.name,
          description: content && content.description ? content.description : bonus.description,
          logoUrl: content && content.image ? bonusFluidImage(content.image) : '',
          termsAndConditions: content && content.bonus_terms ? content.bonus_terms : '',
          maxBonusPercentage: bonus.bonusPercentage,
          maxBonus: defineMaxBonusPerLocale(bonus.maxBonusAmount, locale),
          paymentMethodFilter: {
            // We have a fixed list of excluded payment methods for each bonus
            // Todo: excluded payment methods list should be fetched for together with bonus content from WP
            excludeFrom: [
              {
                providerType: 'NETELLER',
              },
              {
                providerType: 'SKRILL',
              },
              {
                providerType: 'PAYSAFECARD',
              },
              {
                providerType: 'ECOPAYZ',
              },
            ],
          },
        };
      });
  },
);

export const selectHasWelcomeBonuses = createSelector(selectAvailableBonuses, bonuses => {
  if (bonuses.length === 0) return false;
  if (!bonuses.find(bonus => bonus.name.toLowerCase().includes('welcome'))) return false;
  return true;
});

export const selectBonusesTurnedOverPercentage = createSelector(selectActiveBonuses, bonuses => {
  if (bonuses.length === 0) return null;

  let leftToTurnover = 0;
  let totalTurnover = 0;

  bonuses.forEach(bonus => {
    leftToTurnover += bonus.WageringAmount;
    totalTurnover += bonus.BonusWagering;
  });

  if (totalTurnover === 0) return null;

  const staked = totalTurnover - leftToTurnover;

  return Math.floor((staked / totalTurnover) * 100);
});
