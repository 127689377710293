import React from 'react';

import { envIsMexico } from '@/constants/constants';
import MexicoRegistrationForm from '@/features/authentication/registration/mexico/MexicoRegistrationForm';
import FastRegistrationForm from '@/features/authentication/registration/fast-registration/FastRegistrationForm';

const Registration = () => {
  if (envIsMexico()) return <MexicoRegistrationForm />;
  else return <FastRegistrationForm />;
};

export default Registration;
