import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { useEffect } from 'react';
import { kycGetDocuments } from '@/store/actions/kycActions';

const useKycLoader = (): void => {
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  // We decided to fetch the kyc documents across all builds as soon as the user logs in
  useEffect(() => {
    if (!token) {
      return;
    }

    dispatch(kycGetDocuments());
  }, [token]);
};

export default useKycLoader;
