import { put, call } from 'redux-saga/effects';
import { resetLimits } from '../actions/limitsActions';
import { resetUserPlayStatsAndLimits } from '../actions/userPlayStatsAndLimitsActions';
import { resetLoginHistory } from '../actions/loginHistoryActions';
import { resetLastDeposit } from '../actions/lastDepositActions';
import { autoLoginUpdate, refreshUser } from '../actions/autoLoginActions';
import { AutoLoginStep } from '@/models/enum/auto-login-step.enum';
import { kycReset } from '../actions/kycActions';
import { BUILD_MODE } from '@/constants/constants';
import { resetConsents } from '../actions/consentsActions';
import { setFitToPlay } from '../actions/commonActions';
import { resetAml } from '../actions/amlActions';
import { resetUserExtras } from '../actions/userExtrasActions';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import ApiService from '../../shared/apiService';
import { apiV2ErrorHandler } from '@/shared/errorHandler';
import CookiesManager from '../../shared/cookies-manager';
import {
  authenticateWithChallengeTokenError,
  authenticateWithChallengeTokenSuccess,
  logoutSuccess,
} from '@/store/actions/auth';
import { clearUser } from '@/store/actions/user';
import BffApiService from '@/shared/bffApiService';
import { useFluidPaymentsStore } from '@/features/cashier/fluid/fluidPaymentsStore';
import StorageManager from '@/shared/storageManager';

// Login success
export function* loginSuccessSaga(action) {
  const { token } = action;
  CookiesManager.setTokenCookie(token);
  StorageManager.setIsReturningCustomer();
  yield put(refreshUser(token));
  if (BUILD_MODE === 'canada') {
    yield put(setFitToPlay(false));
  }
}

// Logout user
export function* logoutSaga() {
  try {
    yield call(BffApiService.logout);
    triggerGTag(GTagEvents.logout_success);
  } catch (error) {
    // do nothing
  }

  yield put(logoutSuccess());
  useFluidPaymentsStore.getState().closeFluid();
  CookiesManager.removeTokenCookie();
}

export function* logoutSuccessSaga() {
  yield put(autoLoginUpdate(AutoLoginStep.LoggedOut));
  yield put(clearUser());
  yield put(resetLastDeposit());
  yield put(resetLimits());
  yield put(resetLoginHistory());
  yield put(resetUserPlayStatsAndLimits());
  yield put(kycReset());
  yield put(resetConsents());
  yield put(resetAml());
  yield put(resetUserExtras());
}

export function* authenticateWithChallengeTokenSaga(action) {
  const { challengeToken, isNewRegistration } = action;

  try {
    const response = yield ApiService.authenticateWithChallenge({
      challengeToken,
      isNewRegistration,
    });
    const { token } = response.data;
    CookiesManager.setTokenCookie(token);

    // TODO: Add a variable to this action that tells this saga if its a deposit through the cashier or not
    // TODO: so we dont call the login functions here when just making a deposit
    yield put(refreshUser(token));
    yield put(authenticateWithChallengeTokenSuccess(token));
  } catch (error) {
    const mappedError = apiV2ErrorHandler(error);
    yield put(
      authenticateWithChallengeTokenError({
        id: mappedError.errorId,
        message: mappedError.error,
      }),
    );
  }
}
