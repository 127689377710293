import { envIsCanada, envIsNormal } from '@/constants/constants';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { useMemo } from 'react';

const useIsHomePage = () => {
  const { pathname } = useLocation();
  const locale = useSelector(selectLocale);

  return useMemo(() => {
    if (envIsNormal()) {
      return pathname === `/${locale}`;
    }

    if (envIsCanada() && locale === 'ca-fr') {
      return pathname === `/${locale}`;
    }

    // for mexico, sweden and canada (ca)
    return pathname === `/`;
  }, [locale, pathname]);
};

export default useIsHomePage;
