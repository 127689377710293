import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  retry,
  takeEvery,
} from 'redux-saga/effects';
import {
  KycActionType,
  kycGetDocuments,
  kycGetDocumentsError,
  kycGetDocumentsSuccess,
  runVerifyFinished,
} from '@/store/actions/kycActions';
import { SagaIterator } from 'redux-saga';
import { logException } from '@/utils/logger';
import bffApiService from '@/shared/bffApiService';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { bffApiErrorHandler } from '@/shared/errorHandler';
import { AxiosError, AxiosResponse } from 'axios';
import { setShowUserIsPepOrHioLogout } from '@/store/actions/modal';
import { BffErrorCode, BffResponse, BffVerifyUserResponse } from '@lucky7ventures/bff-types';
import { logout } from '@/store/actions/auth';

export function* getKycSaga(): SagaIterator {
  try {
    // This will retry the KYC request after 3 seconds, and it will repeat it 3 times
    const response = yield retry(3, 3000, bffApiService.getKycDocuments);
    yield put(kycGetDocumentsSuccess(response.data.data));
  } catch (error) {
    logException(new Error('KYC Retries Failed'), { error: error });
    yield put(kycGetDocumentsError());
  }
}

export function* runVerifySaga(): Generator<
  CallEffect<AxiosResponse<BffResponse<BffVerifyUserResponse>>> | PutEffect,
  void,
  AxiosResponse<BffResponse<BffVerifyUserResponse>>
> {
  triggerGTag(GTagEvents.user_verify_start);
  try {
    const verifyRes = yield call(bffApiService.verifyUser);
    if (verifyRes.data.data && verifyRes.data.data.isApproved) {
      triggerGTag(GTagEvents.user_verify_approved);
    } else {
      triggerGTag(GTagEvents.user_verify_rejected);
    }
    // after verify is done, we need to fetch the kyc documents again
    yield put(kycGetDocuments());
    yield put(runVerifyFinished());
  } catch (error) {
    const bffError = bffApiErrorHandler(error as AxiosError);
    if (bffError.code === BffErrorCode.GIG_USER_VERIFY_PEPHIO) {
      // if the user is marked as PEP we want to log out the user and present the PEP modal
      triggerGTag(GTagEvents.user_verify_pep);
      yield put(logout());
      yield put(setShowUserIsPepOrHioLogout(true));
    } else {
      triggerGTag(GTagEvents.user_verify_error, { error: `bff-error-${bffError.code}` });
    }
    yield put(runVerifyFinished());
  }
}

export function* watchKyc(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(KycActionType.GetDocuments, getKycSaga)]);
  yield all([takeEvery(KycActionType.RunVerify, runVerifySaga)]);
}
