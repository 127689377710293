import { createSelector } from 'reselect';
import { RootState } from '@/models/root-state.model';

export const selectAuthToken = (state: RootState): string | null => state.auth.token;

export const selectAuthRecentlyLoggedOut = (state: RootState): boolean =>
  state.auth.recentlyLoggedOut;

export const selectAuthIsRegistration = (state: RootState): boolean => state.auth.isRegistration;

export const selectIsAuthenticated = createSelector(selectAuthToken, (token): boolean => {
  return token !== null;
});
