import * as Yup from 'yup';

interface PasswordValildatorProps {
  required: string;
  invalid: string;
}

export function generatePasswordValidation({ required, invalid }: PasswordValildatorProps) {
  return Yup.string()
    .required(required)
    .matches(/^(?=.*\d)(?=.*[a-z]).{6,25}$/, invalid);
}
