import React from 'react';
import { cdnImage } from '@/utils/image-utils';
import { envIsMexico, envIsSweden } from '@/constants/constants';
import { classNames } from '@/utils/utils';
import { ToastsContainer } from '@/components/ToastsContainer';

const desktopProps = { width: '1440', height: '900' };
const tabletProps = { width: '1024', height: '900' };
const mobileProps = { width: '640', height: '900' };

const BG_LARGE_NORMAL = cdnImage('luckydays-beach.jpg', desktopProps);
const BG_MEDIUM_NORMAL = cdnImage('luckydays-beach.jpg', tabletProps);
const BG_SMALL_NORMAL = cdnImage('luckydays-beach.jpg', mobileProps);

const BG_LARGE_MEXICO = cdnImage('ld_mexico_bg_desktop.jpg', desktopProps);
const BG_MEDIUM_MEXICO = cdnImage('ld_mexico_bg_desktop.jpg', tabletProps);
const BG_SMALL_MEXICO = cdnImage('ld_mexico_bg_mobile.jpg', mobileProps);

const BG_LARGE = envIsMexico() ? BG_LARGE_MEXICO : BG_LARGE_NORMAL;
const BG_MEDIUM = envIsMexico() ? BG_MEDIUM_MEXICO : BG_MEDIUM_NORMAL;
const BG_SMALL = envIsMexico() ? BG_SMALL_MEXICO : BG_SMALL_NORMAL;

const HomeHeroContainer = ({
  children,
  isAuthenticated,
}: {
  children: React.ReactNode;
  isAuthenticated: boolean;
}): JSX.Element => {
  return (
    <div
      className={classNames(
        'relative bg-luckyBlue2 flex items-center justify-center',
        envIsSweden() ? 'min-h-heroMobileHeightSweden' : 'min-h-heroMobileHeight',
        isAuthenticated
          ? 'min-h-[calc(90vh-56px-57px-28px)]'
          : 'min-h-[calc(100vh-56px-57px)] md:min-h-screen',
      )}
    >
      <div className="absolute left-0 right-0 bottom-0 top-0 after:absolute after:right-0 after:left-0 after:bottom-0 after:top-0 after:bg-black/50 z-0">
        <img
          className="w-full h-full object-cover object-center"
          src={BG_LARGE}
          srcSet={`${BG_SMALL} 640w, ${BG_MEDIUM} 1024w, ${BG_LARGE} 1440w`}
          alt="Lucky the cat on his way to the beach"
        />
      </div>
      <ToastsContainer />
      <div className="relative z-[10] w-full flex justify-center">{children}</div>
    </div>
  );
};

export default HomeHeroContainer;
