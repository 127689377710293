import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from '@/shared/utility';

export function useWalletTotalBalance() {
  const [totalBalance, setTotalBalance] = useState(null);
  const wallet = useSelector(state => state.user.wallet);

  useEffect(() => {
    if (isEmpty(wallet)) {
      return;
    }

    const balance = wallet.Balance;
    const userBonuses = wallet.UserBonuses;
    const totalUserBonuses = userBonuses.reduce((userBonusesAcc, userBonus) => {
      const bonuses = userBonus.Bonuses;
      if (isEmpty(bonuses)) {
        return userBonusesAcc;
      }

      const totalBonuses = bonuses.reduce(
        (bonusesAcc, bonus) => bonusesAcc + bonus.BonusMoney + bonus.RealBonusMoney,
        0,
      );

      return userBonusesAcc + totalBonuses;
    }, 0);

    setTotalBalance(balance + totalUserBonuses);
  }, [wallet]);

  return totalBalance;
}
