import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { useSimpleRequest } from '@/hooks/useSimpleRequest';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { AuthResponseDto } from '@lucky7ventures/lucky-components';

import { FormInput } from './FormInput';
import { FormPassword } from './FormPassword';
import PromptMessage from './UI/PromptMessage';
import { ButtonWithLoader } from './Common/ButtonWithLoader';
import { useDispatch } from 'react-redux';
import { ApiError } from '@/models/api-error.model';
import { useRegistration } from '@/hooks/useRegistration';
import { closeSpecificModal, openModal } from '@/store/actions/modal';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';

interface LoginPayload {
  username: string;
  password: string;
}

interface LoginForm {
  email: string;
  password: string;
}

export function LoginFirstStep({
  onSuccessfulLogin,
  email,
}: {
  onSuccessfulLogin: (data: AuthResponseDto, payload: LoginPayload) => void;
  email?: string;
}): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { loading, error, request } = useSimpleRequest<AuthResponseDto, LoginPayload>();
  const [loginAttempts, setLoginAttempts] = useState(0);
  const { openRegistration } = useRegistration();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'inputs.email.required' }))
      .email(intl.formatMessage({ id: 'inputs.email.invalid' })),
    password: Yup.string()
      .required(intl.formatMessage({ id: 'inputs.password.required' }))
      .min(6, intl.formatMessage({ id: 'inputs.password.invalid' })),
  });

  const submitHandler = (values: LoginForm) => {
    const payload = { username: values.email, password: values.password };

    const requestCallback = (data: AuthResponseDto) => {
      onSuccessfulLogin(data, payload);
    };

    const errorCallback = (error: ApiError) => {
      if (error.id === 1) {
        setLoginAttempts(prevState => prevState + 1);

        if (loginAttempts > 1) {
          dispatch(openModal('failedLogin'));
        }
      }

      triggerGTag(GTagEvents.login_error, {
        errorId: error.id,
        error: error.message,
      });
    };

    request('/api/auth/v2/login', payload, requestCallback, errorCallback);
  };

  return (
    <div>
      {error && (
        <div className="-mt-4">
          <PromptMessage type="error" message={error} />
        </div>
      )}
      <Formik
        initialValues={{
          email: email ?? '',
          password: '',
        }}
        validationSchema={loginSchema}
        onSubmit={values => submitHandler(values)}
      >
        {({ isValid }) => (
          <Form>
            <FormInput
              data-cy="login-modal-email"
              name="email"
              type="email"
              label={intl.formatMessage({ id: 'inputs.email' })}
              placeholder={intl.formatMessage({
                id: 'inputs.email.placeholder',
              })}
            />
            <FormPassword
              data-cy="login-modal-password"
              id="password"
              name="password"
              label={intl.formatMessage({ id: 'inputs.password' })}
              labelLink={{
                text: intl.formatMessage({ id: 'inputs.password.forgot' }),
                onClick: () => dispatch(openModal('forgotPassword')),
              }}
              placeholder={intl.formatMessage({
                id: 'inputs.password.placeholder',
              })}
            />
            <ButtonWithLoader
              loading={loading}
              message={intl.formatMessage({ id: 'login.login' })}
              disabled={!isValid || loading}
            />
          </Form>
        )}
      </Formik>
      <p className="absolute bottom-8 right-0 left-0 text-sm font-bold">
        {intl.formatMessage({ id: 'login.createAccountText' })}{' '}
        <button
          type="button"
          className="cursor-pointer font-bold text-cyan-500 underline transition hover:text-cyan-600"
          onClick={() => {
            dispatch(closeSpecificModal('login'));
            openRegistration({ openedFrom: OpenedFrom.LoginFirstStep });
          }}
        >
          {intl.formatMessage({ id: 'login.createAccountLink' })}
        </button>
      </p>
    </div>
  );
}
