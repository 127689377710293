import { envIsCanada, envIsMexico, envIsSweden } from './constants';

// TODO: We could create a type for the allowed locales we have
export const buildLocales = (): string[] => {
  if (envIsSweden()) {
    return ['/'];
  }

  if (envIsCanada()) {
    return ['/', 'fr'];
  }

  if (envIsMexico()) {
    return ['/'];
  }

  // Normal Build (.com)
  return ['row', 'fi', 'at', 'nz', 'ca', 'ca-fr', 'za', 'ae', 'th', 'fi-en'];
};
export const FAST_REG_COUNTRIES = [
  {
    name: 'Aland Islands',
    code: 'ax',
  },
  {
    name: 'Andorra',
    code: 'ad',
  },
  {
    name: 'Antarctica',
    code: 'aq',
  },
  {
    name: 'Austria',
    code: 'at',
  },
  {
    name: 'Bahrain',
    code: 'bh',
  },
  {
    name: 'Bangladesh',
    code: 'bd',
  },
  {
    name: 'Bolivia, Plurinational State of',
    code: 'bo',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'ba',
  },
  {
    name: 'Brunei Darussalam',
    code: 'bn',
  },
  {
    name: 'Canada',
    code: 'ca',
  },
  {
    name: 'Chile',
    code: 'cl',
  },
  {
    name: 'Colombia',
    code: 'co',
  },
  {
    name: 'Egypt',
    code: 'eg',
  },
  {
    name: 'Falkland Islands',
    code: 'fk',
  },
  {
    name: 'Georgia',
    code: 'ge',
  },
  {
    name: 'Gibraltar',
    code: 'gi',
  },
  {
    name: 'Guernsey',
    code: 'gg',
  },
  {
    name: 'Iceland',
    code: 'is',
  },
  {
    name: 'Indonesia',
    code: 'id',
  },
  {
    name: 'Ireland',
    code: 'ie',
  },
  {
    name: 'Isle of Man',
    code: 'im',
  },
  {
    name: 'Jersey',
    code: 'je',
  },
  {
    name: 'Jordan',
    code: 'jo',
  },
  {
    name: 'Kenya',
    code: 'ke',
  },
  {
    name: 'Kiribati',
    code: 'ki',
  },
  {
    name: 'Kosovo',
    code: 'xk',
  },
  {
    name: 'Kuwait',
    code: 'kw',
  },
  {
    name: 'Liechtenstein',
    code: 'li',
  },
  {
    name: 'Luxembourg',
    code: 'lu',
  },
  {
    name: 'Moldova',
    code: 'md',
  },
  {
    name: 'Monaco',
    code: 'mc',
  },
  {
    name: 'Mongolia',
    code: 'mn',
  },
  {
    name: 'Morocco',
    code: 'ma',
  },
  {
    name: 'New Zealand',
    code: 'nz',
  },
  {
    name: 'North Macedonia',
    code: 'mk',
  },
  {
    name: 'Norway',
    code: 'no',
  },
  {
    name: 'Oman',
    code: 'om',
  },
  {
    name: 'Paraguay',
    code: 'py',
  },
  {
    name: 'Peru',
    code: 'pe',
  },
  {
    name: 'Qatar',
    code: 'qa',
  },
  {
    name: 'San Marino',
    code: 'sm',
  },
  {
    name: 'Saudi Arabia',
    code: 'sa',
  },
  {
    name: 'South Africa',
    code: 'za',
  },
  {
    name: 'South Korea',
    code: 'kr',
  },
  {
    name: 'Taiwan',
    code: 'tw',
  },
  {
    name: 'Thailand',
    code: 'th',
  },
  {
    name: 'United Arab Emirates',
    code: 'ae',
  },
  {
    name: 'Uruguay',
    code: 'uy',
  },
  {
    name: 'Vietnam',
    code: 'vn',
  },
];

// All provinces except Ontario as we don't allow it on luckydays.com
export const CANADA_PROVINCES = [
  {
    name: 'Alberta',
    code: 'CA-AB',
  },
  {
    name: 'Manitoba',
    code: 'CA-MB',
  },
  {
    name: 'Prince Edward Island',
    code: 'CA-PE',
  },
  {
    name: 'British Columbia',
    code: 'CA-BC',
  },
  {
    name: 'Yukon',
    code: 'CA-YT',
  },
  {
    name: 'Newfoundland and Labrador',
    code: 'CA-NL',
  },
  {
    name: 'Nova Scotia',
    code: 'CA-NS',
  },
  {
    name: 'New Brunswick',
    code: 'CA-NB',
  },
  {
    name: 'Nunavut',
    code: 'CA-NU',
  },
  {
    name: 'Saskatchewan',
    code: 'CA-SK',
  },
  {
    name: 'Quebec',
    code: 'CA-QC',
  },
  {
    name: 'Northwest Territories',
    code: 'CA-NT',
  },
];

// All provinces for .ca registration
export const ONTARIO_REGISTRATION_CANADA_PROVINCES = [
  ...CANADA_PROVINCES,
  {
    name: 'Ontario',
    code: 'CA-ON',
  },
];
