import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { envIsSweden } from '@/constants/constants';
import { useIntl } from 'react-intl';
import { isRegulatedLocale } from '@/shared/utility';
import { useWindowScroll } from 'react-use';
import classNames from 'classnames';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepData,
} from '@/store/authentication/AuthenticationStore';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { sendEventToAppWrapper } from '@/utils/app-wrapper-utils';
import { useLogin } from '@/hooks/useLogin';
import { openModal } from '@/store/actions/modal';
import { useRegistration } from '@/hooks/useRegistration';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import useIsHomePage from '@/hooks/useIsHomePage';

const AuthButton = () => {
  const locale = useSelector(selectLocale);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { openLogin } = useLogin();
  const { openRegistration } = useRegistration();
  const { homeHeroAuthenticationStep } = useHomeHeroAuthenticationStepData();
  const isHomeRoute = useIsHomePage();
  const { y } = useWindowScroll();

  const scrollDistanceHit = useMemo(() => {
    const limit = 0;
    return y > limit;
  }, [y]);

  const handleOpenRegistration = () => {
    triggerGTag(GTagEvents.header_register_click);
    if (isRegulatedLocale(locale)) {
      dispatch(openModal('paynplayIframe', { amount: 0, login: true }));
      return;
    }

    openRegistration({ openedFrom: OpenedFrom.Header });
  };

  const handleLoginClick = () => {
    triggerGTag(GTagEvents.header_login_click);
    sendEventToAppWrapper(GTagEvents.header_login_click);
    openLogin({ openedFrom: OpenedFrom.Header });
  };

  const isRegButtonHidden =
    homeHeroAuthenticationStep === HomeHeroAuthenticationStep.Registration &&
    isHomeRoute &&
    !scrollDistanceHit;

  const isLoginButtonHidden =
    homeHeroAuthenticationStep === HomeHeroAuthenticationStep.Login &&
    !scrollDistanceHit &&
    isHomeRoute;

  if (isRegulatedLocale(locale)) {
    return (
      <button
        data-cy="authentication-button-registration"
        type="button"
        className={classNames(
          'rounded-full px-3 md:px-4 font-bold transition hover:shadow-md text-white py-2',
          {
            'bg-cyan-500 hover:bg-cyan-600 py-2': scrollDistanceHit,
            'bg-cyan-500  hover:bg-cyan-600 md:bg-white/20  md:hover:bg-white/30':
              !scrollDistanceHit,
          },
        )}
        onClick={handleOpenRegistration}
      >
        <p className="m-0 p-0">
          {intl.formatMessage({
            id: envIsSweden() ? 'header.register.zeroDeposit' : 'header.register',
          })}
        </p>
      </button>
    );
  }

  return (
    <>
      <button
        type="button"
        data-cy="authentication-button-login"
        className={classNames('h-10 rounded-full px-4 font-bold transition hover:shadow-md', {
          hidden: isLoginButtonHidden,
          'bg-slate-900/10 text-slate-900 hover:bg-slate-900/20 md:bg-white/20 md:text-white md:hover:bg-white/30':
            !scrollDistanceHit,
          'bg-slate-900/10 text-slate-900 hover:bg-slate-900/20': scrollDistanceHit,
        })}
        onClick={handleLoginClick}
      >
        {intl.formatMessage({ id: 'header.login' })}
      </button>
      <button
        data-cy="authentication-button-registration"
        type="button"
        className={classNames(
          'rounded-full px-3 md:px-4 font-bold transition hover:shadow-md text-white py-2',
          {
            hidden: isRegButtonHidden,
            'bg-cyan-500 hover:bg-cyan-600 py-2': scrollDistanceHit,
            'bg-cyan-500  hover:bg-cyan-600 md:bg-white/20  md:hover:bg-white/30':
              !scrollDistanceHit,
          },
        )}
        onClick={handleOpenRegistration}
      >
        <p className="m-0 p-0 hidden md:block">{intl.formatMessage({ id: 'header.register' })}</p>
        <UserPlusIcon width={20} height={20} className="md:hidden justify-self-center" />
      </button>
    </>
  );
};

export default AuthButton;
