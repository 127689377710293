import { mapLocaleToIetfBcp47Format } from '@/shared/utility';

export const formatCurrency = (
  amount: number,
  locale: string,
  currency: string,
  fractionDigits = 2,
): string => {
  return new Intl.NumberFormat(mapLocaleToIetfBcp47Format(locale), {
    style: 'currency',
    currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(amount);
};

export const getCurrencyCodeForLocale = (locale: string): string => {
  if (locale === 'ae') {
    return 'USD';
  }
  if (locale === 'ca' || locale === 'ca-fr') {
    return 'CAD';
  }
  if (locale === 'nz') {
    return 'NZD';
  }
  if (locale === 'za') {
    return 'ZAR';
  }
  if (locale === 'th') {
    return 'THB';
  }

  return 'EUR';
};
