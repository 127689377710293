import React, { FocusEvent, InputHTMLAttributes, useEffect } from 'react';

import { useField } from 'formik';

import { classNames } from '@/utils/utils';

interface IFastRegistrationFormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  onValidationError?: (error: string) => void;
}

const FastRegistrationFormInput = ({
  label,
  onValidationError,
  ...rest
}: IFastRegistrationFormInputProps): JSX.Element => {
  const { name, className, onBlur } = rest;
  const [field, meta, helpers] = useField(name!);
  const { setValue } = helpers;

  const isEmptyValidationError = meta.touched && meta.error && field.value.length === 0;
  const isValidationError = meta.touched && meta.error && field.value.length > 0;

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    field.onBlur(event);
    onBlur?.(event);
    setValue(event.target.value.trim());
  };

  useEffect(() => {
    if (isValidationError) {
      onValidationError?.(meta.error!);
    }
  }, [isValidationError]);

  return (
    <div className="relative mb-3 w-full">
      <div className="w-full">
        <input
          {...field}
          {...rest}
          className={classNames(
            `w-full px-3.5 pt-6 bg-white/20 text-base text-white text-[15px] disabled:cursor-not-allowed disabled:text-gray-400
              rounded-md border-0 focus:outline-0 focus:border-none focus:ring-0 autofill:!bg-none peer`,
            className,
            isValidationError ? 'rounded-b-none' : 'rounded-b-md',
            isEmptyValidationError ? 'ring-red-500 ring-1' : 'ring-0',
          )}
          id={name}
          onBlur={handleOnBlur}
        />
        <label
          className={classNames(
            `absolute left-2 top-[1px] px-2 transition-transform text-white text-base duration-150 pointer-events-none
            origin-top-left peer-focus:translate-y-1/3 peer-focus:scale-75 peer-autofill:text-black`,
            field.value.length > 0 ? 'translate-y-1/3 scale-75' : 'translate-y-[60%]',
          )}
        >
          {label}
        </label>
      </div>
      <div
        className={classNames(
          `absolute bottom-0 left-0 right-0 transition-opacity duration-1500 translate-y-full bg-white/95 text-[10px]
          text-red-500 rounded-b-md px-1 py-0.5 text-left`,
          isValidationError ? 'opacity-100' : 'opacity-0',
        )}
      >
        {meta.error}
      </div>
    </div>
  );
};

export default FastRegistrationFormInput;
