import React, { useEffect } from 'react';

import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { UserGracePeriodDto } from '@lucky7ventures/lucky-components';

import { TranslatedRichText } from '@/components/TranslatedRichText';
import { useIntl } from 'react-intl';
import { axiosInstance as axios } from '../shared/axiosInstance';
import { isRegulatedLocale } from '@/shared/utility';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { IS_SWEDEN } from '@/constants/constants';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { selectLocale } from '@/store/selectors/commonSelectors';

export function useGracePeriodToast(
  addToastCallback: (message: any) => void,
  cleanupToastCallback: (key: string) => void,
) {
  const token = useSelector(selectAuthToken);
  const locale = useSelector(selectLocale);
  const absoluteNavigate = useAbsoluteNavigate();
  const intl = useIntl();

  const handleCtaClick = () => {
    absoluteNavigate('/settings/verify');
  };

  useEffect(() => {
    const TOAST_ID = 'GRACE_PERIOD_TOAST';

    if (IS_SWEDEN || !token || !isRegulatedLocale(locale)) {
      cleanupToastCallback(TOAST_ID);
      return;
    }

    axios
      .post('/api/user/graceperiod', { token })
      .then((response: AxiosResponse<UserGracePeriodDto[]>) => {
        // Here we want to filter out the ID 7 Grace Period (that translates to Source Of Funds)
        const gracePeriodData = response.data.filter(i => i.DocumentTypeId !== 7);
        if (gracePeriodData.length > 0) {
          const endDateLocaleString = new Date(gracePeriodData[0].EndDate).toLocaleDateString();
          const toast = {
            id: TOAST_ID,
            order: 1,
            subtitle: (
              <TranslatedRichText
                id="gracePeriodToast.text"
                values={{ date: endDateLocaleString }}
              />
            ),
            cta: intl.formatMessage({ id: 'gracePeriodToast.cta' }),
            clickCallback: handleCtaClick,
            closeCallback: () => cleanupToastCallback(TOAST_ID),
          };

          addToastCallback(toast);
        } else {
          cleanupToastCallback(TOAST_ID);
        }
      })
      .catch(() => cleanupToastCallback(TOAST_ID));
  }, [token, locale]);
}
