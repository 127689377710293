import React from 'react';
import Currency from '@/components/UI/Currency';
import { convertJackpotAmount } from '@/utils/logic.utils';

type JackpotCurrencyProps = {
  currencyCode: string;
  amount: number;
};

const JackpotCurrency = ({ currencyCode, amount }: JackpotCurrencyProps) => {
  const convertedAmount = convertJackpotAmount(currencyCode, amount);
  return <Currency currency={currencyCode} amount={convertedAmount} />;
};

export default JackpotCurrency;
