import React, { useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { selectBtag } from '@/store/selectors/commonSelectors';

import * as Yup from 'yup';
import { DateTime } from 'luxon';
import Tooltip from '@/components/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '@/components/buttons/Button';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import BffApiService from '@/shared/bffApiService';
import MexicoRegistrationErrors from '@/features/authentication/registration/mexico/MexicoRegistrationErrors';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { BffRegisterResponse } from '@lucky7ventures/bff-types';
import { BffApiError, BffApiErrorCode } from '@/models/bff-api-error';
import { triggerNetreferSignup } from '@/shared/netrefer';
import { sendEventToAppWrapper } from '@/utils/app-wrapper-utils';
import { loginSuccess } from '@/store/actions/auth';
import Form from '@/components/form/Form';
import TextField from '@/components/form/text/TextField';
import EmailField from '@/components/form/email/EmailField';
import DateOfBirthField from '@/components/form/date-of-birth/DateOfBirthField';
import AlphaNumericField from '@/components/form/alpha-numeric/AlphaNumericField';
import PasswordField from '@/components/form/password/PasswordField';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
} from '@/store/authentication/AuthenticationStore';
import Anchor from '@/components/UI/Buttons/Anchor';

interface RegistrationData {
  email: string;
  curp: string;
  password: string;
  fullName: string;
  dob: {
    day: string;
    month: string;
    year: string;
  };
  btag: string;
}

const initialValues: Omit<RegistrationData, 'btag'> = {
  email: '',
  curp: '',
  password: '',
  fullName: '',
  dob: {
    day: '',
    month: '',
    year: '',
  },
};

const MexicoRegistrationForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [errorCode, setErrorCode] = useState<BffApiErrorCode | null>(null);
  const navigate = useAbsoluteNavigate();

  const { request, loading, success } = useBffApiRequest<BffRegisterResponse>();
  const btag = useSelector(selectBtag);

  const { setHomeHeroAuthenticationStep } = useHomeHeroAuthenticationStepActions();

  const handleError = ({ code }: BffApiError) => {
    triggerGTag(GTagEvents.mx_reg_error, { error: `bff-error-${code}` });
    setErrorCode(code);
  };

  const handleLogin = () => {
    triggerGTag(GTagEvents.home_auth_login_click);
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Login);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleSubmit = (data: Omit<RegistrationData, 'btag'>) => {
    setErrorCode(null);
    triggerGTag(GTagEvents.mx_reg_submit);
    const userData = {
      ...data,
      dob: `${data.dob.year}-${data.dob.month}-${data.dob.day}`,
    };
    request({
      apiMethod: BffApiService.registerMexico,
      payload: { ...userData, btag },
      successCallback: data => {
        triggerGTag(GTagEvents.mx_reg_success, { userId: data.userId });
        triggerNetreferSignup(btag, data.userId, 'mx');
        sendEventToAppWrapper(GTagEvents.mx_reg_success, {
          userId: data.userId,
        });
        dispatch(loginSuccess(data.token));
      },
      errorCallback: handleError,
    });
  };

  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 100;

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .matches(
        /^[a-zA-ZáÁéÉíÍóÓúÚüÜñÑ'\s]+$/,
        intl.formatMessage({ id: 'registration.mexico.error.allowedCharacters' }),
      )
      .test(
        'has-two-or-more-words',
        intl.formatMessage({ id: 'inputs.fullName.atLeastTwoWords' }),
        value => {
          if (typeof value !== 'string') return false;

          // Split the string by spaces and filter out any empty strings
          const words = value.trim().split(/\s+/).filter(Boolean);

          // Check if the number of words is 2 or more
          return words.length >= 2;
        },
      )
      .required(intl.formatMessage({ id: 'input.error.required' })),
    curp: Yup.string()
      .required(intl.formatMessage({ id: 'inputs.curp.required' }))
      .matches(
        /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[HMX](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TL|TS|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z0-9]{1}[0-9]{1}$/,
        intl.formatMessage({ id: 'inputs.curp.invalid-format' }),
      ),
    email: Yup.string()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .email(intl.formatMessage({ id: 'inputs.email.invalid' })),
    password: Yup.string()
      .trim()
      .test(
        'no-whitespace',
        intl.formatMessage({ id: 'inputs.password.whitespaces.invalid' }),
        value => !(value && /\s/.test(value)),
      )
      .matches(/^\S{6,}$/, intl.formatMessage({ id: 'inputs.password.invalid' }))
      .required(intl.formatMessage({ id: 'input.error.required' })),
    dob: Yup.object()
      .shape({
        day: Yup.string()
          .required(intl.formatMessage({ id: 'input.error.required' }))
          .test(
            'valid-day',
            intl.formatMessage({ id: 'inputs.date.day.invalid' }),
            function (value) {
              const month = this.parent.month;
              const year = this.parent.year;
              if (!value || !month || !year) {
                return true; // Skip validation if any field is missing
              }
              const numericDay = parseInt(value);
              const daysInMonth = new Date(parseInt(year), parseInt(month), 0).getDate();
              return numericDay <= daysInMonth && numericDay > 0;
            },
          ),
        month: Yup.string()
          .required(intl.formatMessage({ id: 'input.error.required' }))
          .matches(/^(0?[1-9]|1[0-2])$/, intl.formatMessage({ id: 'inputs.date.month.invalid' })),
        year: Yup.string()
          .required(intl.formatMessage({ id: 'input.error.required' }))
          .matches(/^(19|20)\d\d$/, intl.formatMessage({ id: 'inputs.date.year.invalid' }))
          .test('is-valid-year', 'Invalid year range', value => {
            const numericYear = parseInt(value ?? '', 10);
            return numericYear >= minYear && numericYear <= currentYear;
          }),
      })
      .test(
        'is-18-plus',
        intl.formatMessage({ id: 'inputs.birthdate.invalid' }),
        function ({ day, month, year }) {
          if (!day || !month || !year) {
            return false;
          }
          const dob = DateTime.local(parseInt(year), parseInt(month), parseInt(day));
          const age = DateTime.now().diff(dob, 'years').years;
          return age >= 18;
        },
      ),
  });

  return (
    <>
      <Form
        className="mt-2"
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <>
            <div className="flex flex-col gap-7">
              <TextField
                data-cy="registration-form-full-name"
                name="fullName"
                label={intl.formatMessage({ id: 'inputs.fullName' })}
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
                onValidInput={() => {
                  triggerGTag(GTagEvents.mx_reg_name_valid);
                }}
              />
              <EmailField
                data-cy="registration-form-email"
                label={intl.formatMessage({ id: 'inputs.email' })}
                name="email"
                autoComplete="off"
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
                onValidInput={() => {
                  triggerGTag(GTagEvents.mx_reg_email_valid);
                }}
              />
              <DateOfBirthField
                label={intl.formatMessage({ id: 'inputs.birthdate' })}
                name="dob"
                data-cy="registration-form-dob"
                endIcon={
                  <Tooltip
                    position="left"
                    text={intl.formatMessage({
                      id: 'inputs.dob.tooltip',
                    })}
                    className="max-w-[220px] sm:max-w-max"
                  >
                    <InformationCircleIcon className="w-5 h-5 text-white" />
                  </Tooltip>
                }
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
              />
              <AlphaNumericField
                data-cy="registration-form-curp"
                name="curp"
                label={intl.formatMessage({ id: 'inputs.curp' })}
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
              />
              <PasswordField
                data-cy="registration-form-password"
                label={intl.formatMessage({ id: 'inputs.password' })}
                name="password"
                className="border-[1px] border-white/30 text-white focus-within:border-blue-blue"
              />
              {errorCode && <MexicoRegistrationErrors errorCode={errorCode} />}
              <Button
                data-cy="registration-form-submit-button"
                type="submit"
                isLoading={loading}
                disabled={!isValid}
                text={
                  !loading && success
                    ? intl.formatMessage({ id: 'fastReg.success' })
                    : intl.formatMessage({ id: 'header.register' })
                }
              />
            </div>
          </>
        )}
      </Form>
      <div className="text-base text-white mt-3">
        <FormattedMessage
          id="fastReg.login"
          values={{
            a: str => (
              <span
                data-cy="registration-form-switch-login-button"
                onClick={handleLogin}
                className="text-blue-blue font-bold underline cursor-pointer"
              >
                {str}
              </span>
            ),
          }}
        />
      </div>
      <p className="my-3 block !text-[12px] italic text-white">
        <FormattedMessage
          id="fastReg.consent.text"
          values={{
            a: str => (
              <span
                onClick={() => navigate('/terms-and-conditions')}
                className="text-blue-blue font-bold underline cursor-pointer"
              >
                {str}
              </span>
            ),
          }}
        />
      </p>
      <Anchor
        to=""
        href=""
        className="!no-underline !text-sm text-white"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();
          const bonusInfo = document.getElementById('bonus-info');
          if (bonusInfo !== null) {
            bonusInfo.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }
          triggerGTag(GTagEvents.more_info);
        }}
      >
        {intl.formatMessage({ id: 'hero.moreInfo' })}
      </Anchor>
    </>
  );
};

export default MexicoRegistrationForm;
