import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import {
  selectIsEmailConsented,
  selectIsTextMessageConsented,
} from '@/store/selectors/consentsSelectors';
import { envIsNormal, envIsSweden } from '@/constants/constants';
import { useBankIdProfile } from './useBankIdProfile';
import { openModal } from '@/store/actions/modal';

export const SMS_MAIL_REMINDER_TOAST_ID = 'SMS_MAIL_REMINDER_TOAST';

export function useSmsMailReminderToast(
  addToastCallback: (message: any) => void,
  cleanupToastCallback: (key: string) => void,
  closeToast: (key: string) => () => void,
) {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isSmsConsented = useSelector(selectIsTextMessageConsented);
  const isEmailConsented = useSelector(selectIsEmailConsented);
  const { isCompletedBankIdProfile } = useBankIdProfile();
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleCtaClick = () => {
    dispatch(openModal('smsMailReminder'));
  };

  useEffect(() => {
    // Don't show the toast if:
    // 1. The consents were not yet fetched
    // 2. User is not logged in
    // 3. SMS or Email is consented to true
    // 4. Build is not SWEDEN or CANADA
    // 5. If Sweden build & not completed bank ID profile don't show the toast
    if (
      (isSmsConsented === undefined && isEmailConsented === undefined) ||
      !isAuthenticated ||
      isSmsConsented ||
      isEmailConsented ||
      envIsNormal() ||
      (envIsSweden() && !isCompletedBankIdProfile)
    ) {
      cleanupToastCallback(SMS_MAIL_REMINDER_TOAST_ID);
      return;
    }

    const toast = {
      id: SMS_MAIL_REMINDER_TOAST_ID,
      order: 1,
      subtitle: intl.formatMessage({ id: 'smsMailReminderToast.text' }),
      cta: intl.formatMessage({ id: 'misc.update' }),
      clickCallback: handleCtaClick,
      closeCallback: closeToast(SMS_MAIL_REMINDER_TOAST_ID),
    };

    addToastCallback(toast);
  }, [isAuthenticated, isSmsConsented, isEmailConsented, isCompletedBankIdProfile]);
}
