// Function found and adapted from here: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export const hashCode = string => {
  let hash = 0;
  let i;
  let chr;
  if (string.length === 0) {
    return hash;
  }
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};
