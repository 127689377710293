import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  selectKycDocumentsLoaded,
  selectKycPhotoIdApproved,
  selectKycVerifyInProgress,
} from '@/store/selectors/kycSelectors';
import { BUILD_MODE } from '@/constants/constants';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';

export function usePhotoIdToast(
  addToastCallback: (message: any) => void,
  cleanupToastCallback: (key: string) => void,
): void {
  const photoIdApproved = useSelector(selectKycPhotoIdApproved);
  const documentsLoaded = useSelector(selectKycDocumentsLoaded);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const TOAST_ID = 'PHOTO_ID_REQUIRED_TOAST';
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();
  const verifyInProgress = useSelector(selectKycVerifyInProgress);

  const handleCtaClick = () => {
    navigate('/settings/verify');
  };

  const shouldPresentToast = (
    photoIdApproved: boolean,
    isAuthenticated: boolean,
    documentsLoaded: boolean,
    verifyInProgress: boolean,
  ): boolean => {
    if (BUILD_MODE !== 'canada') {
      return false;
    }

    if (!isAuthenticated) {
      return false;
    }

    if (!documentsLoaded) {
      return false;
    }

    if (verifyInProgress) {
      return false;
    }

    return !photoIdApproved;
  };

  useEffect(() => {
    if (!shouldPresentToast(photoIdApproved, isAuthenticated, documentsLoaded, verifyInProgress)) {
      cleanupToastCallback(TOAST_ID);
      return;
    }

    addToastCallback({
      id: TOAST_ID,
      order: 1,
      subtitle: intl.formatMessage({ id: 'toast.photoId.subtitle' }),
      cta: intl.formatMessage({ id: 'misc.verifyNow' }),
      clickCallback: handleCtaClick,
    });
  }, [photoIdApproved, isAuthenticated, documentsLoaded, verifyInProgress]);
}
