import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useRewardsWheel } from '@/hooks/useRewardsWheel';

const RewardsWheel = lazy(() => import('./RewardsWheel'));
const RewardsWheelHero = lazy(() => import('./RewardsWheelHero'));

function RewardsWheelPage(): JSX.Element {
  const intl = useIntl();
  const { url } = useRewardsWheel();
  return (
    <div>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'rewardReel.title' })}</title>
      </Helmet>
      <Suspense fallback={<div />}>
        {!url && <RewardsWheelHero />}
        <RewardsWheel />
      </Suspense>
    </div>
  );
}

export default RewardsWheelPage;
