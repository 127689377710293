enum StorageKeys {
  CookiesConsent = 'LD_COOKIES_CONSENT',
  ReturningUser = 'LD_RETURNING_USER',
  RewardsWheelReminder = 'LD_REWARDS_WHEEL_REMINDER',
}

const StorageManager = {
  getCookiesConsent: (): { accepted: boolean; performance: boolean } => {
    const current = localStorage.getItem(StorageKeys.CookiesConsent);
    if (!current) {
      return { accepted: false, performance: false };
    }
    const toJson = JSON.parse(current);
    return { accepted: toJson.accepted, performance: toJson.performance };
  },
  saveCookiesConsent: (accepted: boolean, performance: boolean) => {
    const toSave = JSON.stringify({ accepted, performance });
    localStorage.setItem(StorageKeys.CookiesConsent, toSave);
  },
  setIsReturningCustomer: () => localStorage.setItem(StorageKeys.ReturningUser, 'true'),
  getIsReturningUser: () => localStorage.getItem(StorageKeys.ReturningUser),
  setRewardsReelReminder: () =>
    localStorage.setItem(StorageKeys.RewardsWheelReminder, new Date().toISOString()),
  getRewardsWheelReminder: () => localStorage.getItem(StorageKeys.RewardsWheelReminder),
};

export default StorageManager;
