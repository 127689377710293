import { KycActions, KycActionType, KycGetDocumentsSuccessAction } from '../actions/kycActions';
import { BffUserKyc } from '@lucky7ventures/bff-types';

export interface KycState {
  documents: BffUserKyc[];
  documentsLoading: boolean;
  documentsError: boolean;
  verifyInProgress: boolean;
  refreshedOn: Date | null;
}

const initialState = {
  documents: [],
  documentsLoading: false,
  documentsError: false,
  verifyInProgress: false,
  refreshedOn: null,
};

const kycGetDocuments = (state: KycState): KycState => ({
  ...state,
  documentsLoading: true,
});

const kycGetDocumentsSuccess = (
  state: KycState,
  action: KycGetDocumentsSuccessAction,
): KycState => ({
  ...state,
  documentsLoading: false,
  documents: action.documents,
  documentsError: false,
  refreshedOn: new Date(),
});

const kycGetDocumentsError = (state: KycState): KycState => ({
  ...state,
  documentsLoading: false,
  documentsError: true,
});

export const kycReducer = (state = initialState, action: KycActions): KycState => {
  switch (action.type) {
    case KycActionType.GetDocuments:
      return kycGetDocuments(state);
    case KycActionType.GetDocumentsError:
      return kycGetDocumentsError(state);
    case KycActionType.GetDocumentsSuccess:
      return kycGetDocumentsSuccess(state, action);
    case KycActionType.RunVerify:
      return {
        ...state,
        verifyInProgress: true,
      };
    case KycActionType.RunVerifyFinished:
      return {
        ...state,
        verifyInProgress: false,
      };
    case KycActionType.Reset:
      return initialState;
    default:
      return state;
  }
};
