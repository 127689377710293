import React from 'react';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@/components/buttons/Button';
import Form from '@/components/form/Form';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
  useHomeHeroAuthenticationStepData,
} from '@/store/authentication/AuthenticationStore';
import TextField from '@/components/form/text/TextField';
import { useSimpleRequest } from '@/hooks/useSimpleRequest';
import { AuthResponseDto } from '@lucky7ventures/lucky-components';
import { loginSuccess } from '@/store/actions/auth';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { useDispatch } from 'react-redux';
import PromptMessage from '@/components/UI/PromptMessage';
import { ApiError } from '@/models/api-error.model';

interface VerifyPayload {
  username: string;
  password: string;
  otp: string;
}

const initialValues = {
  otp: '',
};

const TwoFactorAuthenticationForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { loading, error, request } = useSimpleRequest<AuthResponseDto, VerifyPayload>();

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
  });

  const { username, password } = useHomeHeroAuthenticationStepData();
  const { setHomeHeroAuthenticationStep, reset } = useHomeHeroAuthenticationStepActions();

  const handleSwitchAuthMethod = () =>
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Login);

  const successCallback = (data: AuthResponseDto) => {
    if (data.Result === 'SUCCESSFUL') {
      dispatch(loginSuccess(data.Token));
      triggerGTag(GTagEvents.login_2fa_success);
      reset();
    }
  };

  const errorCallback = (error: ApiError) => {
    triggerGTag(GTagEvents.login_2fa_error, {
      errorId: error.id,
      error: error.message,
    });
  };

  const handleSubmit = ({ otp }: { otp: string }) => {
    triggerGTag(GTagEvents.login_2fa_submit);
    if (username && password) {
      request(
        '/api/auth/v2/verifyotpcode',
        { otp, username, password },
        successCallback,
        errorCallback,
      );
    }
  };

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      className="flex flex-col w-full justify-between gap-5"
    >
      {() => (
        <>
          <TextField
            data-cy="two-factor-authentication-form-code"
            label={intl.formatMessage({ id: 'inputs.code' })}
            name="otp"
            className="text-white border-[1px] border-transparent focus-within:border-blue-blue bg-white/20"
          />
          <div className="flex flex-col items-center mt-4">
            {error && <PromptMessage type="error" message={error} />}
            <Button
              data-cy="two-factor-authentication-form-submit-button"
              text={intl.formatMessage({ id: 'authentication.two-factor-authentication.verify' })}
              type="submit"
              btnType="primary"
              isLoading={loading}
            />
            <div className="text-white text-[15px] text-center mt-6">
              <FormattedMessage
                id="authentication.two-factor-authentication.description"
                values={{
                  a: str => (
                    <span className="text-blue-blue font-bold underline cursor-pointer">{str}</span>
                  ),
                }}
              />
            </div>
            <p className="max-w-[80%] mt-3 block !text-[12px] italic text-white mb-0">
              <FormattedMessage
                id="authentication.two-factor-authentication.change-to-login"
                values={{
                  a: str => (
                    <span
                      data-cy="two-factor-authentication-form-switch-login-button"
                      className="text-blue-blue font-bold underline cursor-pointer"
                      onClick={handleSwitchAuthMethod}
                    >
                      {str}
                    </span>
                  ),
                }}
              />
            </p>
          </div>
        </>
      )}
    </Form>
  );
};

export default TwoFactorAuthenticationForm;
