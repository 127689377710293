import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  selectKycPOARequested,
  selectKycDocumentsLoaded,
  selectKycSOFRequested,
  selectKycPOPRequested,
} from '@/store/selectors/kycSelectors';
import { BUILD_MODE } from '@/constants/constants';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';

export function useKYCToast(
  addToastCallback: (message: any) => void,
  cleanupToastCallback: (key: string) => void,
): void {
  const kycPOARequested = useSelector(selectKycPOARequested);
  const kycSOFRequested = useSelector(selectKycSOFRequested);
  const kycPOPRequested = useSelector(selectKycPOPRequested);
  const documentsLoaded = useSelector(selectKycDocumentsLoaded);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const TOAST_ID = 'KYC_ID_REQUIRED_TOAST';
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();

  const handleCtaClick = () => {
    navigate('/settings/verify');
  };

  const shouldPresentToast = (
    kycSOFRequested: boolean,
    kycPOPRequested: boolean,
    kycPOARequested: boolean,
    isAuthenticated: boolean,
    documentsLoaded: boolean,
  ): boolean => {
    if (BUILD_MODE !== 'canada') {
      return false;
    }

    if (!isAuthenticated) {
      return false;
    }

    if (!documentsLoaded) {
      return false;
    }

    return kycPOARequested || kycPOPRequested || kycSOFRequested;
  };

  useEffect(() => {
    if (
      !shouldPresentToast(
        kycPOARequested,
        kycPOPRequested,
        kycSOFRequested,
        isAuthenticated,
        documentsLoaded,
      )
    ) {
      cleanupToastCallback(TOAST_ID);
      return;
    }

    addToastCallback({
      id: TOAST_ID,
      order: 1,
      subtitle: intl.formatMessage({ id: 'toast.missingKyc.subtitle' }),
      cta: intl.formatMessage({ id: 'misc.verifyNow' }),
      clickCallback: handleCtaClick,
    });
  }, [kycPOARequested, isAuthenticated, documentsLoaded]);
}
